import React, { useState, useEffect, useRef, useCallback } from "react";
import Short from "./Short";
import './ShortsContainer.css';
import { getShortVideosRecommendation, getVideoDetailsByID } from "../../services/APIServices/videos.api";
import { useAppSelector } from "../../store/hooks";
import { getUser } from "../../store/reducers/user.reducer";
import axios from "axios";
import { videoUpperLimit } from "../../utils/constants";
import throttle from "lodash/throttle";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { Box, CircularProgress, IconButton } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import { getChannelVideos } from "../../services/APIServices/channel.api";
import { isMobile } from "react-device-detect";

function ShortContainer() {
  const { channelID, videoID } = useParams();
  const location = useLocation();
  const isShareRoute = location.pathname.includes("/share");
  const isChannelRoute = location.pathname.includes("/channel");

  const shortContainerRef = useRef(null);
  const shortRef = useRef(null);
  const observerRef = useRef(null);
  const lastVideoRef = useRef(null);
  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const [currentLowerLimit, setCurrentLowerLimit] = useState(0);
  const [currentUpperLimit, setCurrentUpperLimit] = useState(9);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const user = useAppSelector((state) => getUser(state));

  useEffect(() => {
    setIsLoading(true);

    if (isShareRoute) {
      getVideoDetailsByID(videoID)
        .then((videoDetails) => {
          setVideos([videoDetails]); // Ek hi video set karenge
          setIsLoading(false);
        })
        .catch((err) => {
          console.error("Error fetching video:", err);
          setIsLoading(false);
        });
    } else if (isChannelRoute && channelID) {
      getChannelVideos(channelID).then((res) => {
        if (res?.length > 0) {
          const shortVideos = res.filter((video) => video.videoType === "short");
          const currentVideo = shortVideos.find((video) => video.videoID === videoID);
          const otherVideos = shortVideos.filter((video) => video.videoID !== videoID);

          const sortedVideos = currentVideo ? [currentVideo, ...otherVideos] : shortVideos;
          setVideos(sortedVideos);
        }
      }).catch((err) => {
        console.error("Error fetching channel videos:", err);
      })
        .finally(() => {
          setIsLoading(false);
        });
      setIsLoading(false);
    } else if (videoID) {
      // ✅ Home Page se jo video click hua hai, uske liye ye call karega
      getVideoDetailsByID(videoID)
        .then((videoDetails) => {
          if (videoDetails) {
            setVideos([videoDetails]); // Pehle clicked video ko set karna

            // ✅ Ab recommended videos bhi fetch karo
            getShortVideosRecommendation({
              lowerLimit: 0,
              upperLimit: videoUpperLimit,
              userID: user?.userID,
              videotype: "short",
            }).then((response) => {
              if (Array.isArray(response)) {
                const filteredVideos = response.filter((video) => video.videoID !== videoID);

                setVideos((prevVideos) => [...prevVideos, ...filteredVideos]); // Append recommended videos
                setHasNext(response.length >= videoUpperLimit);
              }
            }).catch((err) => console.error("Error fetching recommended videos:", err));
          }
        })
        .catch((err) => console.error("Error fetching video:", err))
        .finally(() => setIsLoading(false));
    } else {
      // 🟢 Agar normal shorts list fetch karni ho (default behavior)
      getShortVideosRecommendation({
        lowerLimit: 0,
        upperLimit: videoUpperLimit,
        userID: user?.userID,
        videotype: "short",
      })
        .then((response) => {
          if (Array.isArray(response)) {
            setVideos((prevVideos) => [...prevVideos, ...response]); // Append new videos to the list
            setHasNext(response.length >= videoUpperLimit);
          } else {
            console.error("Unexpected response format:", response);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.error("Error fetching recommended videos:", err);
          setIsLoading(false);
        });
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [channelID, videoID]);

  // useEffect(() => {
  //   const cancelTokenSource = axios.CancelToken.source();
  //   setIsLoading(true);
  //   if (videos && videos.length === 0) {
  //     getShortVideosRecommendation({
  //       lowerLimit: 0,
  //       upperLimit: 9,
  //       userID: user?.userID,
  //       videotype: "short",
  //     })
  //       .then((response) => {
  //         console.log("response:", response);
  //         if (Array.isArray(response)) {
  //           setVideos((prevVideos) => [...prevVideos, ...response]); // Append new videos to the list
  //           setHasNext(response.length === videoUpperLimit);
  //         } else {
  //           console.error("Unexpected response format:", response);
  //         }
  //         setIsLoading(false);
  //       })
  //       .catch((err) => {
  //         console.error("Error fetching videos:", err);
  //         setIsLoading(false);
  //       });
  //   }

  //   return () => {
  //     cancelTokenSource.cancel("Request cancelled");
  //   };
  // }, [user?.userID]);

  const loadMoreVideos = () => {
    console.log("loadMoreVideos triggered");
    if (!isLoading && hasNext) {
      setIsLoading(true);

      const newLowerLimit = currentUpperLimit + 1;
      const newUpperLimit = newLowerLimit + videoUpperLimit;
      const currentScrollTop = shortContainerRef.current.scrollTop;

      getShortVideosRecommendation({
        lowerLimit: newLowerLimit,
        upperLimit: newUpperLimit,
        userID: user?.userID,
        videotype: "short",
      })
        .then((response) => {
          if (Array.isArray(response) && response.length > 0) {
            setVideos((prevVideos) => {
              return [...prevVideos, ...response];
            });

            setHasNext(response.length >= videoUpperLimit);
            setCurrentLowerLimit(newLowerLimit);
            setCurrentUpperLimit(newUpperLimit);

            setTimeout(() => {
              shortContainerRef.current.scrollTo({
                top: currentScrollTop + window.innerHeight,
                behavior: "instant",
              });
            }, 10);

          } else {
            setHasNext(false);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.error("Error fetching videos:", err);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    const checkLastReelInView = throttle(() => {
      if (shortRef.current) {
        setCurrentVideoIndex(shortRef.current.getCurrentVideoIndex());
      }

      const lastReel = document.querySelector(".reel:last-child");

      if (lastReel) {
        const lastReelVideo = lastReel.querySelector(".reel-video");

        if (lastReelVideo && lastReelVideo.classList.contains("active")) {

          if (!lastReel || isLoading) return;

          // const currentScrollTop = shortContainerRef.current.scrollTop;

          const rect = lastReel.getBoundingClientRect();

          if (rect.top < (window.innerHeight * 5) && rect.bottom >= 0) {
            loadMoreVideos();
            // shortContainerRef.current.scrollTo({
            //   top: currentScrollTop + window.innerHeight,
            //   behavior: "smooth",
            // });
          }
        }
      }
    }, 300);

    shortContainerRef.current.addEventListener("scroll", checkLastReelInView);
    checkLastReelInView();

    return () => {
      if (shortContainerRef.current) {  // ✅ Fix: Ensure it exists before removing
        shortContainerRef.current.removeEventListener("scroll", checkLastReelInView);
      }
    };
  }, [isLoading]);

  const handleScrollUp = () => {
    if (shortContainerRef.current) {
      shortContainerRef.current.scrollTo({
        top: shortContainerRef.current.scrollTop - window.innerHeight,
        behavior: "smooth",
      });
    }
  };

  const handleScrollDown = () => {
    if (shortContainerRef.current) {
      shortContainerRef.current.scrollTo({
        top: shortContainerRef.current.scrollTop + window.innerHeight,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (shortRef.current) {
      setCurrentVideoIndex(shortRef.current.getCurrentVideoIndex());
    }
  }, []);

  return (
    <div className="short-container-wrapper">
      <section ref={shortContainerRef} className="short-container">
        {isLoading ? (
          <Box
            position="absolute"
            top="50%"
            left="50%"
            sx={{ transform: "translate(-50%, -50%)" }}
          >
            <CircularProgress sx={{ color: "blue" }} />
          </Box>
        ) : videos.length > 0 ? (
          videos.map((short, index) => (
            <Short
              key={index}
              short={short}
              shortContainerRef={shortContainerRef}
              index={index}
              isActive={index === currentVideoIndex}
              ref={shortRef}
            />
          ))
        ) : (
          <p>No videos available</p>
        )}
      </section>
      {!isMobile ? (
        <div className="navigation-container">
          {currentVideoIndex !== 0 && (<IconButton onClick={handleScrollUp} className="nav-up">
            <ArrowUpward />
          </IconButton>)}
          <IconButton onClick={handleScrollDown} className="nav-down">
            <ArrowDownward />
          </IconButton>
        </div>
      ) : null

      }

    </div>
  );
}

export default ShortContainer;
