import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import VideosCard from "../../components/video-card/index";
import { useAppSelector } from "../../store/hooks";
import { getSearchResults } from "../../store/reducers/search.reducer";
import { RootState } from "../../store";
import { SearchElement } from "../../types/search";
import { Box, Container, Divider, TextField } from "@mui/material";
import ChannelCard from "../../components/channel-profile-card/ChannelCard";
import { getChannelVideos } from "../../services/APIServices/channel.api";
import { ShortVideosCard } from "../Shorts/Shorts";
import { PAGE_ROUTES } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import logoOnly from "../../assets/IndeeFlix-logo-only.png";

// export default function SearchPage() {
//   const data = useAppSelector((state: RootState) => getSearchResults(state));

//   const videos = data?.filter((ele: any) => ele.type === "Video").map((ele: any) => ele.video) || [];
//   const channels = data?.filter((ele: any) => ele.type === "Channel") || [];

//   return (
//     <Container
//       sx={{
//         justifyContent: "center",
//         alignItems: "center",
//       }}
//     >
//       <Typography style={{ margin: "15px 0px 10px 0px" }}>
//         Search Results...
//       </Typography>
//       <Divider style={{ margin: "15px 0px 10px 0px", visibility: "inherit" }} />

//       {data?.length ? (
//         <>
//           {videos?.length > 0 && GetElementByType("Video", videos)}
//           {channels?.map((ele: any) => GetElementByType("Channel", ele))}
//         </>
//       ) : (
//         <Typography variant={"h6"}>No Results Found!</Typography>
//       )}
//     </Container>
//   );
// }

// const GetElementByType = (type: string, details: any) => {
//   const [channelVideos, setChannelVideos] = useState([]);

//   useEffect(() => {
//     if (type === "Channel" && details?.channel?.channelID) {
//       getChannelVideos(details.channel.channelID).then((res: any) => {
//         if (res) setChannelVideos(res);
//       });
//     }
//   }, [type, details]);

//   const getElement = () => {
//     switch (type) {
//       case "Video":
//         return <VideosCard sx={{ width: "100%" }} data={details} />;

//       case "Channel":
//         return (
//           <Container>
//             <ChannelCard data={details.channel} fullWidth />
//             <VideosCard sx={{ width: "100%" }} data={channelVideos} />
//           </Container>
//         );

//       default:
//         return <Typography variant={"h6"}>No Result Found!</Typography>;
//     }
//   };

//   return type && details ? (
//     getElement()
//   ) : (
//     <Typography variant={"h6"}>No Result Found!</Typography>
//   );
// };

export default function SearchPage() {
  const data = useAppSelector((state: RootState) => getSearchResults(state));

  const videos = data?.filter((ele: any) => ele.type === "Video").map((ele: any) => ele.video) || [];
  const channels = data?.filter((ele: any) => ele.type === "Channel") || [];
  const shortVideos = videos?.filter((ele: any) => ele.videoType === "short") || [];

  return (
    <Container
      sx={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography style={{ margin: "15px 0px 10px 0px" }}>
        Search Results...
      </Typography>
      <Divider style={{ margin: "15px 0px 10px 0px", visibility: "inherit" }} />

      {data?.length ? (
        <>
          {videos?.length > 0 && <ElementByType type="Video" details={videos} />}
          {shortVideos?.length > 0 && <ElementByType type="ShortVideo" details={shortVideos} />}
          {channels?.map((ele: any) => (
            <ElementByType key={ele.channel.channelID} type="Channel" details={ele} />
          ))}
        </>
      ) : (
        <Typography variant={"h6"}>No Results Found!</Typography>
      )}
    </Container>
  );
}

const ElementByType: React.FC<{ type: string; details: any }> = ({ type, details }) => {
  const [channelVideos, setChannelVideos] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (type === "Channel" && details?.channel?.channelID) {
      getChannelVideos(details.channel.channelID).then((res: any) => {
        if (res) setChannelVideos(res);
      });
    }
  }, [type, details]);

  if (!type || !details) {
    return <Typography variant={"h6"}>No Result Found!</Typography>;
  }

  switch (type) {
    case "ShortVideo":
      return <>
      <Box sx={{ mt: 2, p: 2, bgcolor: "background.paper", borderRadius: 2 }}>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <img src={logoOnly} alt="Theme Logo" width={30} height={30} style={{ marginRight: 8 }} />
                <Typography variant="h6" fontWeight="bold">
                  Shorts
                </Typography>
              </Box>
      <ShortVideosCard isLoading={false} data={details} onVideoClick={(videoId: string, channelID?: string) =>
        navigate(`${PAGE_ROUTES.SHORT_VIDEO_PAGE}/${videoId}`)
      } /></Box></>;
      
    case "Video":
      const fullVideos = details.filter((video: any) => video.videoType !== "short");
      return <VideosCard sx={{ width: "100%" }} data={fullVideos} />;

    case "Channel":
      return (
        <Container>
          <ChannelCard data={details.channel} fullWidth />
          <VideosCard sx={{ width: "100%" }} data={channelVideos} />
        </Container>
      );
    default:
      return <Typography variant={"h6"}>No Result Found!</Typography>;
  }
};

