import { Card, CardHeader, Container, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Comments from "../components/Comments/Comments";
import VideoPlayer from "../components/Player/VideoPlayer";
import VideosCard from "../components/video-card";
import {
  getChannelByID,
  getChannelVideos,
} from "../services/APIServices/channel.api";
import {
  getHomePageVideos,
  getHomePageVideosRecommendation,
  getVideoDetailsByID,
} from "../services/APIServices/videos.api";
import { useAppSelector } from "../store/hooks";
import { getUser } from "../store/reducers/user.reducer";
import { ChannelDetails } from "../types/channel";
import { VideoResponse } from "../types/medial";
import axios from "axios";
import { videoUpperLimit } from "../utils/constants";
import { ShortVideosCard } from "./Shorts/Shorts";

export const WatchPage = () => {
  let { videoID } = useParams();
  const [videoDetails, setVideoDetails] = useState<VideoResponse | null>(null);
  const [channel, setChannel] = useState<ChannelDetails | null>(null);
  const [loading, setLoading] = useState(false);
  const [suggestionVideos, setSuggestionVideos] = useState<
    VideoResponse[] | null
  >(null);
  const [channelVideos, setChannelVideos] = useState<VideoResponse[] | null>(
    null
  );
  const user = useAppSelector((state) => getUser(state));
  const navigate = useNavigate();
  const [cancelToken, setCancelToken] = useState<any>(null);
  const playerSide = useRef<any>(null);
  const [hasNext, setHasNext] = useState(false);
  const [currentLowerLimit, setCurrentLowerLimit] = useState(0);
  const [currentUpperLimit, setCurrentUpperLimit] = useState(9);

  useEffect(() => {
    if (videoID && videoDetails?.videoID !== videoID) {
      const cancelTokenSource = axios.CancelToken.source();
      setCancelToken(cancelTokenSource);
      getVideoDetailsByID(videoID, cancelTokenSource.token).then((res: any) => {
        setVideoDetails(res);
        getChannelByID(res?.channelID as string, user?.userID).then(
          (res: any) => {
            setChannel(res);
          }
        );
      });
    } else if (!videoID) navigate(-1);

    return () => {
      setChannel(null);
      setVideoDetails(null);
      if (cancelToken) {
        cancelToken.cancel("Request cancelled");
      }
    };
  }, [videoID]);

  useEffect(() => {
    getHomePageVideosRecommendation({
      lowerLimit: 0,
      upperLimit: videoUpperLimit,
      userID: user?.userID,
    })
      .then((response: any) => {
        if (response?.length >= 0) {
          // const filteredVideos = response.filter(
          //   (video: any) => video.videoType !== 'short'
          // );
          setSuggestionVideos(
            response.filter((video: any) => video.videoID !== videoID)
          );
          setHasNext(response.length >= videoUpperLimit);
          setLoading(false);
        }
      })
      .catch((err: any) => console.error(err));

    return () => {
      setSuggestionVideos([]);
    };
  }, [videoDetails]);

  useEffect(() => {
    if (channel) {
      getChannelVideos(channel?.channelID).then((res: any) => {
        setChannelVideos(res.filter((video: any) => video.videoID !== videoID));
      });
    }
  }, [channel, videoID]);
  
  const loadMore = () => {
    if (!loading && hasNext) {
      setLoading(true);
      const newLowerLimit = currentUpperLimit + 1;
      const newUpperLimit = newLowerLimit + videoUpperLimit - 1;

      setCurrentLowerLimit(newLowerLimit);
      setCurrentUpperLimit(newUpperLimit);
      const cancelTokenSource = axios.CancelToken.source();
      setCancelToken(cancelTokenSource);

      getHomePageVideosRecommendation({
        lowerLimit: newLowerLimit,
        upperLimit: newUpperLimit,
        userID: user?.userID,
      })
        .then((response: any) => {
          if (response?.length >= 0) {

            const filteredVideos = response.filter(
              (video: any) => video.videoType !== 'short'
            );

            setSuggestionVideos((prevSuggestionVideos: any) => [
              ...prevSuggestionVideos,
              ...filteredVideos.filter((video: VideoResponse) => video.videoID !== videoID),
            ]);
            setHasNext(response?.length === videoUpperLimit);
            setLoading(false);
          }
        })
        .catch((err) => console.error(err));
      return () => {
        if (cancelTokenSource) {
          cancelTokenSource.cancel("Request cancelled");
        }
      };
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const nearBottom =
        window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight - 100;

      if (nearBottom && !loading && hasNext) {
        loadMore();
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loading, hasNext, loadMore]);

  return (
    // <Container maxWidth={false}>
    //   <Grid container maxWidth={"100%"} spacing={1}>
    //     <Grid item xs={12} sm={12} lg={8} md={6} >
    //       <Stack direction={"column"} spacing={2} ref={playerSide}>
    //         <VideoPlayer
    //           videoDetails={videoDetails}
    //           setVideoDetails={setVideoDetails}
    //           channel={channel}
    //           setChannel={setChannel}
    //         />
    //         <Comments videoID={videoID} />
    //       </Stack>
    //     </Grid>
    //     <Grid item xs={12} sm={12} lg={4} md={6}>
    //       {
    //         <div style={{backgroundColor:"rgba(256,256,256, 0.1)", borderRadius: "5px",height: playerSide?.current?.clientHeight, overflowY: "scroll"}}>
    //         <VideosCard
    //           small
    //           maxWidth={12}
    //           data={channelVideos as VideoResponse[]}
    //           isLoading={false}
    //         />
    //         </div>
    //       }
    //     </Grid>
    //   </Grid>
    //   <VideosCard
    //           small
    //           maxWidth={4}
    //           data={suggestionVideos as VideoResponse[]}
    //           isLoading={suggestionVideos === null}
    //           loadMore={loadMore}
    //         />
    // </Container>

    <Container maxWidth={false}>
      <Grid container maxWidth={"100%"} spacing={1}>
        {/* Left Side: Video Player + Comments */}
        <Grid item xs={12} sm={12} lg={8} md={6}>
          <Stack direction={"column"} spacing={2} ref={playerSide}>
            <VideoPlayer
              videoDetails={videoDetails}
              setVideoDetails={setVideoDetails}
              channel={channel}
              setChannel={setChannel}
            />
            <Comments videoID={videoID} />
          </Stack>
        </Grid>

        {/* Right Side: Channel Videos (Including Shorts) */}
        <Grid item xs={12} sm={12} lg={4} md={6}>
          <div
            style={{
              backgroundColor: "rgba(256,256,256, 0.1)",
              borderRadius: "5px",
              height: playerSide?.current?.clientHeight,
              overflowY: "scroll",
            }}
          >
            {/* Channel Videos - Separate Shorts & Normal Videos */}
            {/* {channelVideos?.some((video : any) => video.videoType === "short") && (
              <ShortVideosCard
                data={channelVideos.filter((video: any) => video.videoType === "short")}
                // onVideoClick={(videoId: any) => navigate(`/shorts/${videoId}`)}
              />
            )} */}

            <VideosCard
              small
              maxWidth={12}
              data={channelVideos?.filter((video: any) => video.videoType !== "short")}
              isLoading={false}
            />
          </div>
        </Grid>
      </Grid>

      {/* Recommended Videos (Including Shorts) */}
      {/* {suggestionVideos?.some((video: any) => video.videoType === "short") && (
        <ShortVideosCard
          data={suggestionVideos?.filter((video: any) => video.videoType === "short")}
          // onVideoClick={(videoId: any) => navigate(`/shorts/${videoId}`)}
        />
      )} */}

      <VideosCard
        small
        maxWidth={4}
        data={suggestionVideos?.filter((video: any) => video.videoType !== "short")}
        isLoading={suggestionVideos === null}
        loadMore={loadMore}
      />
    </Container>

  );
};
