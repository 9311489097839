import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { uploadVideo } from "../../services/APIServices/channel.api";
import { VideoWrapper } from "../../types/channel";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getUser } from "../../store/reducers/user.reducer";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { setMessage } from "../../store/reducers/message.reducer";
import { setLoading } from "../../store/reducers/loading.reducer";
import { updateVideoDetail } from "../../services/APIServices/videos.api";
import LinearProgress from "@mui/material/LinearProgress";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const suggestedCategories = [
  { tags: "Comedy" },
  { tags: "Gaming" },
  { tags: "Music" },
  { tags: "Education" },
  { tags: "Entertainment" },
  { tags: "Video/Animation" },
];

interface UploadVideoProps {
  defaultOpen?: boolean;
  width?: number | string;
  height?: number | string;
  onModalClose?: () => void;
  isEditing?: boolean;
  details?: any;
  onEditSuccess?: () => void;
}

const initialValue = {
  title: "",
  description: "",
  channelId: "",
  userID: "",
  ageLimit: 0,
  category: "",
};

const UploadVideo: React.FC<UploadVideoProps> = (props) => {
  const {
    width,
    height,
    onModalClose = () => { },
    defaultOpen = false,
    isEditing,
    details,
    onEditSuccess,
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const thumbnailRef = useRef<HTMLInputElement>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [source, setSource] = useState<string | undefined>();
  const [thumbnailSource, setThumbnailSource] = useState<string | undefined>();
  const [formData, setFormData] = useState<VideoWrapper>(initialValue);
  const [isCancelled, setIsCancelled] = useState(false);
  const [open, setOpen] = React.useState(defaultOpen);
  const [showVideoTypePopup, setShowVideoTypePopup] = useState(false);
  const [selectedVideoType, setSelectedVideoType] = useState<string | null>(null);
  const [videoDuration, setVideoDuration] = useState<number | null>(null);
  const [showDurationError, setShowDurationError] = useState(false);
  const user = useAppSelector((state) => getUser(state));
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [showProcessingMessage, setShowProcessingMessage] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(() => {
    setOpen(defaultOpen);

    return () => {
      setOpen(false);
    };
  }, [defaultOpen]);

  useEffect(() => {
    if (user) {
      setFormData((prev) => ({
        ...prev,
        userID: user.userID,
        channelId: user.channelList?.[0]?.channelID,
      }));
    }
  }, [user]);

  useEffect(() => {
    if (isEditing) {
      setThumbnailSource(details?.thumbnailPic);
      setFormData({
        title: details.title,
        description: details.description,
        channelId: details.channelId,
        userID: details.userID,
        ageLimit: details.ageLimit,
        category: details.category,
      });
    }
  }, [isEditing, details]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setSource(url);
      setFormData((prev) => ({ ...prev, title: file.name?.split(".")[0] }));
    }
  };

  const handleMetadataLoaded = () => {
    if (videoRef.current) {
      console.log("Video Duration:", videoRef.current.duration);
      setVideoDuration(videoRef.current!.duration);
    }
  };

  const handleThumbnailChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file && file.type.startsWith("image/")) {
      const url = URL.createObjectURL(file);
      setThumbnailSource(url);
    }
  };

  const handleOpen = () => {
    setIsCancelled(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSource(undefined);
    setThumbnailSource(undefined);
    setFormData(initialValue);
    onModalClose();
    navigate(-1);
  };

  const onUploadProgress = (progressEvent: any) => {
    const progress = Math.round(
      (progressEvent.loaded / progressEvent.total) * 100
    );
    setUploadProgress(progress);
    if (progress === 100) {
      toast.success(
        "Your video is being processed. Feel free to navigate away or stay on this page."
      );
    }
  };

  const handleUpload = async () => {
    if (isCancelled) return;

    const videoFile = inputRef.current?.files?.[0];
    const thumbnailFile = thumbnailRef.current?.files?.[0];

    if (!formData.title) {
      toast.error("Please enter a title before uploading!");
      return;
    }

    if (!videoFile) {
      toast.error("Please select a video file before uploading!");
      return;
    }

    if (!thumbnailFile) {
      toast.error("Please select a thumbnail image before uploading!");
      return;
    }

    if (!selectedVideoType) {
      setShowVideoTypePopup(true);
      return;
    }

    if (selectedVideoType === "short" && videoDuration !== null && videoDuration > 30) {
      setShowDurationError(true);
      return;
    }

    if (thumbnailSource) {
      setFormData((prev) => ({
        ...prev,
        thumbnailFileLocation: thumbnailSource,
      }));
    }

    setUploading(true);
    toast.dismiss();
    uploadVideo(videoFile, thumbnailFile, formData, onUploadProgress, selectedVideoType)
      .then((res: any) => {
        handleClose();
        setUploading(false);
        setUploadProgress(0);

        if (res?.statusCode === 500) {
          toast.error(res?.message || "Server error occurred!");
        } else if (res?.statusCode === 400) {
          toast.error(res?.message || "Invalid request! Please check your inputs.");
        } else {
          toast.success("Video Uploaded Successfully!");
        }
      })
      .catch((error) => {
        console.error("Error uploading video:", error);
        setUploadProgress(0);

        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message || "Invalid request! Please check your inputs.");
        } else if (error?.response?.status === 500) {
          toast.error(error?.response?.data?.message || "Server error occurred!");
        } else {
          toast.error("Something went wrong, please try again!");
        }
      });
  };


  const renderVideoTypePopup = () => (
    <Modal
      open={showVideoTypePopup}
      onClose={() => setShowVideoTypePopup(false)}
      aria-labelledby="select-upload-option-title"
      aria-describedby="select-upload-option-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 300,
          bgcolor: "background.paper",
          border: "none",
          boxShadow: 24,
          p: 4,
          borderRadius: "8px",
        }}
      >
        <Typography
          id="select-upload-option-title"
          variant="h6"
          gutterBottom
          sx={{ textAlign: "center", fontWeight: "bold" }}
        >
          Choose Upload Option
        </Typography>
        <Typography
          id="select-upload-option-description"
          variant="body2"
          gutterBottom
          sx={{
            textAlign: "center",
            color: "#666",
            marginBottom: 3,
            fontSize: "0.9rem",
          }}
        >
          Do you want to upload as a short or as a Video?
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            gap: 1.5,
          }}
        >
          <Typography
            onClick={() => {
              setSelectedVideoType("short");
              setShowVideoTypePopup(false);
              handleUpload();
            }}
            sx={{
              color: "#003a80",
              cursor: "pointer",
              fontSize: "1rem",
              "&:hover": {
                color: "#003a80",
              },
            }}
          >
            Upload as Short Video
          </Typography>
          <Typography
            onClick={() => {
              setSelectedVideoType("long");
              setShowVideoTypePopup(false);
              handleUpload();
            }}
            sx={{
              color: "#003a80",
              cursor: "pointer",
              fontSize: "1rem",
              "&:hover": {
                color: "#003a80",
              },
            }}
          >
            Upload as Video
          </Typography>
        </Box>
      </Box>
    </Modal>
  );

  // Popup for long video error
  const renderDurationErrorPopup = () => (
    <Modal
      open={showDurationError}
      onClose={() => setShowDurationError(false)}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 300,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: "8px",
        }}
      >
        <Typography variant="h6" sx={{ textAlign: "center", fontWeight: "bold" }}>
          Video is too long
        </Typography>
        <Typography variant="body2" sx={{ textAlign: "center", color: "#666", mt: 1 }}>
          Please upload a short video of 30 seconds or less.
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            gap: 1.5,
          }}
        >
          <Typography
            onClick={() => { setShowDurationError(false); setSelectedVideoType(null); }}
            sx={{
              color: "#003a80",
              cursor: "pointer",
              fontSize: "1rem",
              "&:hover": {
                color: "#003a80",
              },
            }}
          >
            Ok
          </Typography>
        </Box>
      </Box>
    </Modal>
  );

  const handleUpdate = async () => {
    updateVideoDetail({
      ageLimit: formData?.ageLimit,
      category: [formData?.category],
      description: formData?.description,
      title: formData?.title,
      userID: user?.userID,
      videoID: details?.videoID,
    }).then((res: any) => {
      if (res?.success) {
        console.log("Video details updated successfully:", res.message);
      } else {
        console.log("Failed to update video details:", res?.message);
      }
      onEditSuccess?.();
    });
  };

  // return (
  //   <div className="VideoInput">
  //     <Dialog
  //       open={open}
  //       onClose={handleClose}
  //       className="Modal"
  //       fullScreen
  //       fullWidth
  //       maxWidth="md"
  //       PaperProps={{
  //         style: {
  //           width: "100%",
  //           height: "100%",
  //           margin: "auto",
  //           padding: "15px",
  //         },
  //       }}
  //     >
  //       <DialogTitle>{"Upload Video"}</DialogTitle>
  //       <DialogContent dividers>
  //         <Box>
  //           <div className="ScrollableContainer">
  //             <Stack direction={"column"}>
  //               {!isEditing ? (
  //                 <>
  //                   <Box
  //                     style={{
  //                       marginTop: "20px",
  //                       padding: "8px",
  //                       borderRadius: "5px",
  //                       width: "100%",
  //                       maxWidth: "600px",
  //                       margin: "auto",
  //                     }}
  //                   >
  //                     <label htmlFor="videoInput">
  //                       <b>Video File </b>
  //                     </label>
  //                     <Divider />
  //                     <input
  //                       style={{
  //                         marginTop: "20px",
  //                         padding: "8px",
  //                         borderRadius: "5px",
  //                         width: "100%",
  //                         maxWidth: "600px",
  //                         margin: "auto",
  //                       }}
  //                       ref={inputRef}
  //                       id="videoInput"
  //                       className="VideoInput_input"
  //                       type="file"
  //                       onChange={handleFileChange}
  //                       accept="video/*"
  //                     />
  //                     {source && (
  //                       <video
  //                         style={{
  //                           marginTop: "5px",
  //                           maxHeight: "400px",
  //                           width: "100%",
  //                         }}
  //                         className="VideoInput_video"
  //                         width="100%"
  //                         height={height}
  //                         controls
  //                         src={source}
  //                       />
  //                     )}
  //                   </Box>
  //                 </>
  //               ) : null}
  //               {!isEditing ? (
  //                 <>
  //                   <Box
  //                     style={{
  //                       marginTop: "20px",
  //                       padding: "8px",
  //                       borderRadius: "5px",
  //                       width: "100%",
  //                       maxWidth: "600px",
  //                       margin: "auto",
  //                     }}
  //                   >
  //                     <label htmlFor="thumbnailInput">
  //                       <b>Video Thumbnail </b>
  //                     </label>
  //                     <Divider />
  //                     <input
  //                       style={{
  //                         marginTop: "20px",
  //                         padding: "8px",
  //                         borderRadius: "5px",
  //                         width: "100%",
  //                         maxWidth: "600px",
  //                         margin: "auto",
  //                       }}
  //                       ref={thumbnailRef}
  //                       id="thumbnailInput"
  //                       className="ThumbnailInput_input"
  //                       type="file"
  //                       onChange={handleThumbnailChange}
  //                       accept="image/*"
  //                     />
  //                     {thumbnailSource && (
  //                       <img
  //                         className="ThumbnailInput_image"
  //                         src={thumbnailSource}
  //                         alt="Thumbnail"
  //                         style={{
  //                           marginTop: "16px",
  //                           maxHeight: "300px",
  //                           aspectRatio: "16/9",
  //                           objectFit: "cover",
  //                           width: "100%",
  //                         }}
  //                       />
  //                     )}
  //                   </Box>
  //                 </>
  //               ) : null}
  //               <TextField
  //                 size="small"
  //                 label="Title"
  //                 variant="outlined"
  //                 fullWidth
  //                 value={formData.title}
  //                 onChange={(e: any) =>
  //                   setFormData((prev) => ({ ...prev, title: e.target.value }))
  //                 }
  //                 style={{
  //                   margin: "20px auto 0px",
  //                   maxWidth: "600px",
  //                 }}
  //               />

  //               <TextField
  //                 size="small"
  //                 label="Description"
  //                 variant="outlined"
  //                 fullWidth
  //                 value={formData.description}
  //                 onChange={(e: any) =>
  //                   setFormData((prev) => ({
  //                     ...prev,
  //                     description: e.target.value,
  //                   }))
  //                 }
  //                 style={{
  //                   margin: "20px auto 0px",
  //                   maxWidth: "600px",
  //                 }}
  //               />

  //               <Autocomplete
  //                 size="small"
  //                 multiple={false}
  //                 // freeSolo
  //                 style={{
  //                   width: "100%",
  //                   margin: "20px auto 0px",
  //                   maxWidth: "600px",
  //                 }}
  //                 id="tags-categories"
  //                 onInputChange={(e, v) => {
  //                   setFormData((prev) => ({ ...prev, category: v }));
  //                   console.log("v", v);
  //                 }}
  //                 options={suggestedCategories.map((option) => option.tags)}
  //                 renderInput={(params) => (
  //                   <TextField
  //                     required
  //                     {...params}
  //                     label="Categories"
  //                     placeholder="Categories"
  //                     fullWidth
  //                   />
  //                 )}
  //               />
  //               <TextField
  //                 size="small"
  //                 label="Age Limit"
  //                 variant="outlined"
  //                 type="number"
  //                 defaultValue={0}
  //                 fullWidth
  //                 value={formData.ageLimit}
  //                 onChange={(e: any) =>
  //                   setFormData((prev) => ({
  //                     ...prev,
  //                     ageLimit: e.target.value,
  //                   }))
  //                 }
  //                 style={{
  //                   margin: "20px auto 0px",
  //                   maxWidth: "600px",
  //                 }}
  //               />
  //             </Stack>
  //           </div>
  //         </Box>
  //       </DialogContent>
  //       <DialogActions>
  //         <Button
  //           sx={{ marginRight: 2 }}
  //           variant="outlined"
  //           color={"secondary"}
  //           size="medium"
  //           onClick={handleClose}
  //           disabled={uploading}
  //         >
  //           CANCEL
  //         </Button>
  //         {isEditing ? (
  //           <Button
  //             variant="contained"
  //             size="medium"
  //             onClick={handleUpdate}
  //             disabled={!formData.title || uploading}
  //           >
  //             UPDATE
  //           </Button>
  //         ) : (
  //           <Button
  //             variant="contained"
  //             size="medium"
  //             onClick={handleUpload}
  //             disabled={
  //               !inputRef.current?.files?.[0] || !formData.title || uploading
  //             }
  //           >
  //             UPLOAD
  //           </Button>
  //         )}
  //       </DialogActions>
  //     </Dialog>
  //   </div>
  // );

  // ====================
  return (
    <div className="VideoInput">
      {/* <Dialog
        open={open}
        onClose={handleClose}
        className="Modal"
        fullScreen
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: {
            width: "100%",
            height: "100%",
            margin: "auto",
            padding: "15px",
          },
        }}
      >
        <DialogTitle>{"Upload Video"}</DialogTitle>
        <DialogContent dividers> */}
      <Box sx={{ padding: "15px", maxWidth: "100%", margin: "auto" }}>
        <Typography variant="h5" sx={{ marginBottom: 2 }}>
          Upload Video
        </Typography>
        <Box className="ScrollableContainer">
          <Stack direction={"column"}>
            {/* Grid Layout for Video File and Video Thumbnail */}
            {/* <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Box
                      style={{
                        padding: "8px",
                        borderRadius: "5px",
                        width: "100%",
                      }}
                    >
                      <label htmlFor="videoInput">
                        <b>Video File</b>
                      </label>
                      <Divider />
                      <input
                        style={{
                          marginTop: "20px",
                          padding: "8px",
                          borderRadius: "5px",
                          width: "100%",
                        }}
                        ref={inputRef}
                        id="videoInput"
                        className="VideoInput_input"
                        type="file"
                        onChange={handleFileChange}
                        accept="video/*"
                      />
                      {source && (
                        <video
                          style={{
                            marginTop: "5px",
                            maxHeight: "200px",
                            width: "100%",
                          }}
                          controls
                          src={source}
                        />
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box
                      style={{
                        padding: "8px",
                        borderRadius: "5px",
                        width: "100%",
                      }}
                    >
                      <label htmlFor="thumbnailInput">
                        <b>Video Thumbnail</b>
                      </label>
                      <Divider />
                      <input
                        style={{
                          marginTop: "20px",
                          padding: "8px",
                          borderRadius: "5px",
                          width: "100%",
                        }}
                        ref={thumbnailRef}
                        id="thumbnailInput"
                        className="ThumbnailInput_input"
                        type="file"
                        onChange={handleThumbnailChange}
                        accept="image/*"
                      />
                      {thumbnailSource && (
                        <img
                          src={thumbnailSource}
                          alt="Thumbnail"
                          style={{
                            marginTop: "16px",
                            maxHeight: "200px",
                            width: "100%",
                            objectFit: "cover",
                          }}
                        />
                      )}
                    </Box>
                  </Grid>
                </Grid> */}
            {/* 
{uploading && (
  <Box sx={{ width: "100%", marginY: 2 }}>
    <LinearProgress variant="determinate" value={uploadProgress} />
    <Typography variant="body2" align="center">
      Uploading: {uploadProgress}%
    </Typography>
  </Box>
)} */}

            <Grid container spacing={2}>
              {/* Video File Input */}
              <Grid item xs={12} sm={6}>
                <Box
                  style={{
                    padding: "8px",
                    borderRadius: "5px",
                    width: "100%",
                  }}
                >
                  <label htmlFor="videoInput">
                    <b>Video File</b>
                  </label>
                  <Divider sx={{ marginY: 1 }} />
                  <Button
                    variant="contained"
                    component="label"
                    color="primary"
                    style={{
                      marginTop: "20px",
                      padding: "8px 16px",
                      borderRadius: "5px",
                    }}
                  >
                    Choose Video File
                    <input
                      ref={inputRef}
                      id="videoInput"
                      className="VideoInput_input"
                      type="file"
                      onChange={handleFileChange}
                      accept="video/*"
                      hidden
                    />
                  </Button>
                  {source && (
                    <video
                      ref={videoRef}
                      style={{
                        marginTop: "10px",
                        maxHeight: "200px",
                        width: "100%",
                      }}
                      controls
                      src={source}
                      onLoadedMetadata={handleMetadataLoaded}
                    />
                  )}
                </Box>
              </Grid>

              {/* Video Thumbnail Input */}
              <Grid item xs={12} sm={6}>
                <Box
                  style={{
                    padding: "8px",
                    borderRadius: "5px",
                    width: "100%",
                  }}
                >
                  <label htmlFor="thumbnailInput">
                    <b>Video Thumbnail</b>
                  </label>
                  {/* <Divider /> */}
                  <Divider sx={{ marginY: 1 }} />
                  <Button
                    variant="contained"
                    component="label"
                    color="primary"
                    style={{
                      marginTop: "20px",
                      padding: "8px 16px",
                      borderRadius: "5px",
                    }}
                  >
                    Choose Thumbnail File
                    <input
                      ref={thumbnailRef}
                      id="thumbnailInput"
                      className="ThumbnailInput_input"
                      type="file"
                      onChange={handleThumbnailChange}
                      accept="image/*"
                      hidden
                    />
                  </Button>
                  {thumbnailSource && (
                    <img
                      src={thumbnailSource}
                      alt="Thumbnail"
                      style={{
                        marginTop: "16px",
                        maxHeight: "200px",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginTop: "20px" }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  size="small"
                  label="Title"
                  variant="outlined"
                  fullWidth
                  value={formData.title}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      title: e.target.value,
                    }))
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  size="small"
                  label="Age Limit"
                  variant="outlined"
                  type="number"
                  fullWidth
                  value={formData.ageLimit}
                  onChange={(e: any) => {
                    const value = Math.max(0, Number(e.target.value));
                    setFormData((prev) => ({
                      ...prev,
                      ageLimit: value,
                    }));
                  }}
                />

              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  size="small"
                  label="Description"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={1}
                  value={formData.description}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      description: e.target?.value,
                    }))
                  }
                />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <Autocomplete
                  size="small"
                  multiple={false}
                  id="tags-categories"
                  freeSolo={false}
                  onInputChange={(e, v) =>
                    setFormData((prev) => ({
                      ...prev,
                      category: v,
                    }))
                  }
                  options={suggestedCategories.map((option) => option.tags)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Categories"
                      placeholder="Categories"
                      required
                    />
                  )}
                  fullWidth
                />
              </Grid> */}

              <Grid item xs={12} sm={6}>
                <Autocomplete
                  size="small"
                  id="tags-categories"
                  options={suggestedCategories.map((option) => option.tags)}
                  onChange={(e, v: any) =>
                    setFormData((prev: VideoWrapper) => ({
                      ...prev,
                      category: v,
                    }))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Categories"
                      placeholder="Select a category"
                      required
                    />
                  )}
                  fullWidth
                />
              </Grid>

            </Grid>

            {/* Upload Progress */}
            {/* {uploading && (
            <Box sx={{ width: "100%", marginY: 2 }}>
              <LinearProgress variant="determinate" value={uploadProgress} />
              <Typography variant="body2" align="center">
                Uploading: {uploadProgress}%
              </Typography>
            </Box>
          )} */}

            {/* Full Screen Loader with Progress Bar */}
            {uploading && (
              <Box
                sx={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100vw",
                  height: "100vh",
                  backgroundColor: "rgba(0, 0, 0, 0.5)", // 🔥 Semi-transparent background
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 1300, // 🔥 High z-index to overlay everything
                }}
              >
                <Box sx={{ width: "50%", backgroundColor: "#fff", padding: "20px", borderRadius: "8px" }}>
                  <Typography variant="h6" align="center" sx={{ marginBottom: 2 }}>
                    Uploading Video...
                  </Typography>
                  <LinearProgress variant="determinate" value={uploadProgress} sx={{ height: 8, borderRadius: 4 }} />
                  <Typography variant="body2" align="center" sx={{ marginTop: 1 }}>
                    {uploadProgress}%
                  </Typography>
                </Box>
              </Box>
            )}

            {/* Action Buttons */}
            <Box sx={{ marginTop: 2, display: "flex", justifyContent: "flex-end" }}>
              <Button
                sx={{ marginRight: 2 }}
                variant="outlined"
                color="secondary"
                size="medium"
                onClick={handleClose}
                disabled={uploading}
              >
                Cancel
              </Button>
              {isEditing ? (
                <Button
                  variant="contained"
                  size="medium"
                  onClick={handleUpdate}
                  disabled={!formData.title || !formData.category || uploading}
                >
                  Update
                </Button>
              ) : (
                <Button
                  variant="contained"
                  size="medium"
                  onClick={handleUpload}
                  disabled={
                    !inputRef.current?.files?.[0] || !formData.title || !formData.category || uploading
                  }
                >
                  Upload
                </Button>
              )}

              {renderVideoTypePopup()}
              {renderDurationErrorPopup()}
            </Box>
          </Stack>
        </Box>
      </Box>
      {/* </DialogContent>
        <DialogActions> */}
      {/* <Button
            sx={{ marginRight: 2 }}
            variant="outlined"
            color={"secondary"}
            size="medium"
            onClick={handleClose}
            disabled={uploading}
          >
            CANCEL
          </Button> */}
      {/* {isEditing ? (
            <Button
              variant="contained"
              size="medium"
              onClick={handleUpdate}
              disabled={!formData.title || !formData.category || uploading}
            >
              UPDATE
            </Button>
          ) : (
            <Button
              variant="contained"
              size="medium"
              onClick={handleUpload}
              disabled={
                !inputRef.current?.files?.[0] || !formData.title || !formData.category || uploading
              }
            >
              UPLOAD
            </Button>
          )} */}
      {/* </DialogActions>
      </Dialog> */}
    </div>
  );
};

export default UploadVideo;
