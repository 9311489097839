import React, { useState, useRef } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { channelProfileTabMenu, PAGE_ROUTES } from "../../utils/constants";
import { Search } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import { Button, InputBase, InputBaseProps } from "@mui/material";
import VideoCard from "../video-card/index";
import UploadVideo from "../UploadVideo/UploadVideo";
import { useNavigate } from "react-router-dom";
import Shorts, { ShortVideosCard } from "../../pages/Shorts/Shorts";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SearchIconWrapper = styled("div")(({ theme }) => ({
  height: "100%",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

interface CustomInputBasePros extends InputBaseProps {
  open?: boolean;
}

const StyledInputBase = styled(
  InputBase,
  {}
)<CustomInputBasePros>(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "50ch",
  },
}));

interface TabBarProps {
  viewOnly?: boolean;
  data: any;
  isLoading: boolean;
  handleUploadVideo?: () => void;
}

export default function TabBar({
  viewOnly,
  data,
  isLoading,
  handleUploadVideo = () => { },
}: TabBarProps) {
  const [value, setValue] = React.useState(0);
  const [uploadedVideo, setUploadedVideo] = useState(false);
  const navigate = useNavigate();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    // setValue(newValue);

    if (newValue === channelProfileTabMenu.length - 1) {
      navigate(PAGE_ROUTES.UPLOAD_VIDEO_PAGE);
    } else {
      setValue(newValue); // Update the active tab for other cases
    }
  };

  const handleUploadButton = () => {
    // setUploadedVideo(true);
    navigate(PAGE_ROUTES.UPLOAD_VIDEO_PAGE);
  };

  const onModalClose = () => {
    setValue(0);
    setUploadedVideo(false);
    handleUploadVideo();
  };
  const filteredVideos = data?.filter((video: any) => video?.videoType !== "short");

  const filteredShorts = data?.filter((video: any) => video?.videoType === "short");

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        padding: "20px", // Adjust the padding as needed
        margin: "20px 0", // Adjust the margin as needed
        "@media (max-width: 600px)": {
          position: "relative",
          maxWidth: "100%",
        },
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {(viewOnly
            ? channelProfileTabMenu?.filter((tab) => tab?.viewOnly)
            : channelProfileTabMenu
          ).map((menu, index) => (
            <Tab label={menu.title} {...a11yProps(index)} key={index} />
          ))}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
          </Box>
        </Tabs>
      </Box>
      {data?.length || isLoading ? (
        channelProfileTabMenu?.map((menu, index) => (
          <TabPanel value={value} index={index} key={index}>
            {index === 0 ? (
              // Videos Tab
              <VideoCard small data={filteredVideos} isLoading={isLoading} />
            ) : index === 1 ? (
              // Shorts Tab
              <>
                {filteredShorts?.length > 0 ? (
                  <ShortVideosCard data={filteredShorts}
                  isLoading={isLoading}
                    onVideoClick={(videoId: string, channelID?: string) =>
                      navigate(`${PAGE_ROUTES.SHORT_VIDEO_PAGE}/channel/${channelID}/${videoId}`)
                    } />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingBlock: "15%",
                    }}
                  >
                    {viewOnly ? (
                      <Typography>No videos available</Typography>
                    ) : (
                      <Button onClick={handleUploadButton} variant="contained">
                        Upload Shorts
                      </Button>
                    )}
                  </div>
                )}

              </>
            ) : (
              // Upload Tab
              <Button onClick={handleUploadButton} variant="contained">
                Upload Video
              </Button>
            )}
          </TabPanel>
        ))
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBlock: "15%",
          }}
        >
          {viewOnly ? (
            <Typography>No videos available</Typography>
          ) : (
            <Button onClick={handleUploadButton} variant="contained">
              Upload Video
            </Button>
          )}
        </div>
      )}

      {/* {value === channelProfileTabMenu.length - 1 ? (
        // <UploadVideo defaultOpen onModalClose={onModalClose} width={"80vw"} />
        <Button onClick={handleUploadButton} variant="contained">
        Upload Video
      </Button>
      ) : data?.length || isLoading ? (
        channelProfileTabMenu.map((menu, index) => (
          <TabPanel value={value} index={index}>
            {<VideoCard small data={data} isLoading={isLoading} />}
          </TabPanel>
        ))
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBlock: "15%",
          }}
        >
          {viewOnly ? (
            <Typography>No video available</Typography>
          ) : (
            <Button onClick={handleUploadButton} variant="contained">
              Upload Video
            </Button>
          )}
        </div>
      )} */}
      {/* <UploadVideo
        defaultOpen={uploadedVideo}
        onModalClose={onModalClose}
        width={"80vw"}
      /> */}
    </Box>
  );
}
