import { ApiHelper } from "../heplers/APIHelper";
const apiHelper = new ApiHelper();
const publicApiHelper = new ApiHelper({ isPublic: true });

export const getHomePageVideos = ({ lowerLimit, upperLimit, userID = "", category, cancelToken }: any) => {
    return publicApiHelper.get("video/home", { lowerLimit, upperLimit, userID, category }, cancelToken);
};

export const getHomePageVideosRecommendation = ({ lowerLimit, upperLimit, userID }: any) => {
    return publicApiHelper.get("recommend/getVideoRecommendation", { lowerLimit, upperLimit, userID });
};

export const getShortVideosRecommendation = ({ lowerLimit, upperLimit, userID, videotype }: any) => {
    return publicApiHelper.get("recommend/getVideoRecommendation", { lowerLimit, upperLimit, userID, videotype });
};

export const getVideoByID = (videoID: string, range: string, watch: string) => {
    return publicApiHelper.get("video/getVideo", { videoID });
}

export const getVideoDetailsByID = (videoID: string, cancelToken?: any) => {
    return publicApiHelper.get("video/videoDetail", { videoID }, cancelToken);
}

export const reportVideo = (userID: string, videoID: string, message?: string) => {
    return apiHelper.post("Feedback/spam", { message, userID, videoID })
}
export const dislikeVideo = (userID: string, videoID: string) => {
    return apiHelper.post("Feedback/dislike", { message: "dislike", userID, videoID })
}
export const likeVideo = (userID: string, videoID: string) => {
    return apiHelper.post("Feedback/like", { message: "like", userID, videoID })
}

export const getComments = (videoID: string, lowerLimit = 0, upperLimit = 10) => {
    return publicApiHelper.post(`comment/getComments?videoID=${videoID}&lowerLimit=${lowerLimit}&upperLimit=${upperLimit}`, {});
}

export const getReplies = (commentID: string, lowerLimit = 0, upperLimit = 10) => {
    return publicApiHelper.post(`comment/getReplies?commentID=${commentID}&lowerLimit=${lowerLimit}&upperLimit=${upperLimit}`, {});
}

export const addComments = (message: string, userID: string, videoID: string, commentID?: string) => {
    let data: any = {
        message, userID, videoID
    }
    if (commentID) {
        data["commentID"] = commentID;
    }
    return apiHelper.post('comment/addComment', data);
}

export const addReply = (message: string, userID: string, videoID: string, commentID?: string) => {
    let data: any = {
        message, userID, videoID
    }
    if (commentID) {
        data["commentID"] = commentID;
    }
    return apiHelper.post('comment/addReply', data);
}

export const deleteVideo = (videoID: string, cancelToken?: any) => {
    return publicApiHelper.get("video/deleteVideo", { videoID }, cancelToken);
}

export const updateVideoDetail = (videoDetails: any) => {
    return apiHelper.post("video/updateVideoDetail", videoDetails);
}

export const getFeedbackStatus = (userID: any, videoID: any) => {
    const responseBody = apiHelper.post("Feedback/feedbackStatus", { userID, videoID });
    console.log('responseBody :',responseBody);
    return responseBody; 
}