import React, { useEffect, useState } from "react";
import VideosCard from "../components/video-card/index";
import { getHomePageVideos, getHomePageVideosRecommendation, getShortVideosRecommendation } from "../services/APIServices/videos.api";
import { useAppSelector } from "../store/hooks";
import { getUser } from "../store/reducers/user.reducer";
import SuggestionsTab from "../components/SuggestionsTab/SuggestionsTab";
import { Box, CircularProgress, Container, Typography } from "@mui/material";
import axios from "axios";
import { PAGE_ROUTES, videoUpperLimit } from "../utils/constants";
import { ShortVideosCard } from "./Shorts/Shorts";
import { useNavigate } from "react-router-dom";
import logoOnly from "../assets/IndeeFlix-logo-only.png";

// export default function Home() {
//   const [videos, setVideos] = useState<any>([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [hasNext, setHasNext] = useState(false);
//   const [currentLowerLimit, setCurrentLowerLimit] = useState(0);
//   const [currentUpperLimit, setCurrentUpperLimit] = useState(9);

//   const user = useAppSelector((state) => getUser(state));
//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const [cancelToken, setCancelToken] = useState<any>(null);

//   useEffect(() => {
//     setIsLoading(true);
//     setCurrentLowerLimit(0);
//     setCurrentUpperLimit(videoUpperLimit);

//     const cancelTokenSource = axios.CancelToken.source();
//     setCancelToken(cancelTokenSource);

//     const apiCall = selectedCategory
//       ? getHomePageVideos
//       : getHomePageVideosRecommendation;

//     apiCall({
//       lowerLimit: 0,
//       upperLimit: videoUpperLimit,
//       userID: user?.userID,
//       category: selectedCategory,
//       cancelToken: cancelTokenSource.token,
//     })
//       .then((response: any) => {
//         if (response?.length >= 0) {
//           setVideos(response);
//           setHasNext(response?.length >= videoUpperLimit);
//         }
//       })
//       .catch((err) => console.error(err))
//       .finally(() => setIsLoading(false));

//     return () => {
//       cancelTokenSource.cancel("Request cancelled");
//     };
//   }, [user?.userID, selectedCategory]);

//   const loadMore = () => {
//     if (!isLoading && hasNext) {
//       setIsLoading(true);
//       const newLowerLimit = currentUpperLimit + 1;
//       const newUpperLimit = newLowerLimit + videoUpperLimit;

//       setCurrentLowerLimit(newLowerLimit);
//       setCurrentUpperLimit(newUpperLimit);

//       const cancelTokenSource = axios.CancelToken.source();
//       setCancelToken(cancelTokenSource);

//       const apiCall = selectedCategory
//         ? getHomePageVideos
//         : getHomePageVideosRecommendation;

//       apiCall({
//         lowerLimit: newLowerLimit,
//         upperLimit: newUpperLimit,
//         userID: user?.userID,
//         category: selectedCategory,
//         cancelToken: cancelTokenSource.token,
//       })
//         .then((response: any) => {
//           if (response?.length >= 0) {
//             setVideos((prevVideos: any) => [...prevVideos, ...response]);
//             setHasNext(response?.length >= videoUpperLimit);
//           }
//         })
//         .catch((err) => console.error(err))
//         .finally(() => setIsLoading(false));

//       return () => {
//         cancelTokenSource.cancel("Request cancelled");
//       };
//     }
//   };

//   useEffect(() => {
//     const handleScroll = () => {
//       const nearBottom =
//         window.innerHeight + window.scrollY >=
//         document.documentElement.scrollHeight - 100;
//         console.log("handleScroll nearBottom :", nearBottom);
//         if (nearBottom && !isLoading && hasNext) {
//         loadMore(); 
//         console.log("Calling load more");
//       }
//     };

//     window.addEventListener("scroll", handleScroll);

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, [isLoading, hasNext, loadMore]);  

//   return (
//     <Container>
//       <SuggestionsTab
//         selectedCategory={selectedCategory}
//         setSelectedCategory={setSelectedCategory}
//       />
//       <VideosCard isLoading={isLoading} data={videos} loadMore={loadMore}/>
//     </Container>
//   );
// }

// export default function Home() {
//   const [videos, setVideos] = useState<any[]>([]);
//   const [shortVideos, setShortVideos] = useState<any[]>([]);
//   const [mergedList, setMergedList] = useState<any[]>([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [hasNext, setHasNext] = useState(false);
//   const [currentLowerLimit, setCurrentLowerLimit] = useState(0);
//   const [currentUpperLimit, setCurrentUpperLimit] = useState(9);

//   const user = useAppSelector((state) => getUser(state));
//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const [cancelToken, setCancelToken] = useState<any>(null);
//   const navigate = useNavigate();

//   useEffect(() => {
//     setIsLoading(true);
//     setCurrentLowerLimit(0);
//     setCurrentUpperLimit(videoUpperLimit);

//     const cancelTokenSource = axios.CancelToken.source();
//     setCancelToken(cancelTokenSource);

//     const apiCall = selectedCategory
//       ? getHomePageVideos
//       : getHomePageVideosRecommendation;

//     Promise.all([
//       apiCall({
//         lowerLimit: 0,
//         upperLimit: videoUpperLimit,
//         userID: user?.userID,
//         category: selectedCategory,
//         cancelToken: cancelTokenSource.token,
//       }),
//       getShortVideosRecommendation({
//         lowerLimit: 0,
//         upperLimit: videoUpperLimit,
//         userID: user?.userID,
//         videotype: "short",
//       }),
//     ])
//       .then(([fullVideosRes, shortVideosRes]) => {
//         const fullVideos = fullVideosRes as unknown as any[];  // Extract actual data
//         const shortVideos = shortVideosRes as unknown as any[]; // Extract actual data
//         console.log("fullVideos :", fullVideosRes);
//         console.log("shortVideos :", shortVideosRes);
//         setVideos(fullVideos || []);
//         setShortVideos(shortVideos || []);
//         mergeVideoData(fullVideos, shortVideos);
//         setHasNext(fullVideos.length >= videoUpperLimit);
//       })
//       .catch((err) => console.error(err))
//       .finally(() => setIsLoading(false));

//     return () => {
//       cancelTokenSource.cancel("Request cancelled");
//     };
//   }, [user?.userID, selectedCategory]);

//   const mergeVideoData = (full: any[], shorts: any[]) => {
//     let merged: any[] = [];
//     let shortIndex = 0;
//     let fullCount = 0;

//     full.forEach((video, index) => {
//       merged.push(video);
//       fullCount++;

//       if (fullCount % 5 === 0 && shortIndex < shorts.length) {
//         merged.push({ type: "shorts", data: shorts[shortIndex] });
//         shortIndex++;
//       }
//     });
//     console.log("merged: ", merged);
//     setMergedList(merged);
//   };

//   const loadMore = () => {
//     if (!isLoading && hasNext) {
//       setIsLoading(true);
//       const newLowerLimit = currentUpperLimit + 1;
//       const newUpperLimit = newLowerLimit + videoUpperLimit;

//       setCurrentLowerLimit(newLowerLimit);
//       setCurrentUpperLimit(newUpperLimit);

//       const cancelTokenSource = axios.CancelToken.source();
//       setCancelToken(cancelTokenSource);

//       const apiCall = selectedCategory
//         ? getHomePageVideos
//         : getHomePageVideosRecommendation;

//       Promise.all([
//         apiCall({
//           lowerLimit: newLowerLimit,
//           upperLimit: newUpperLimit,
//           userID: user?.userID,
//           category: selectedCategory,
//           cancelToken: cancelTokenSource.token,
//         }),
//         getShortVideosRecommendation({
//           lowerLimit: newLowerLimit,
//           upperLimit: newUpperLimit,
//           userID: user?.userID,
//           videotype: "short",
//         }),
//       ])
//         .then(([fullVideosRes, shortVideosRes]) => {
//           const fullVideos = fullVideosRes.data || [];  // Extracting actual data
//           const shortVideos = shortVideosRes.data || [];

//           setVideos((prevVideos) => {
//             const updatedFullVideos = [...prevVideos, ...fullVideos];
//             setShortVideos((prevShorts) => {
//               const updatedShortVideos = [...prevShorts, ...shortVideos];

//               // Merge karne ka function call ab sahi state ke saath hoga
//               mergeVideoData(updatedFullVideos, updatedShortVideos);

//               return updatedShortVideos; // State update ke liye return zaroori hai
//             });

//             return updatedFullVideos; // State update ke liye return zaroori hai
//           });
//           setHasNext(fullVideos.length >= videoUpperLimit);
//         })
//         .catch((err) => console.error(err))
//         .finally(() => setIsLoading(false));

//       return () => {
//         cancelTokenSource.cancel("Request cancelled");
//       };
//     }
//   };

//   useEffect(() => {
//     const handleScroll = () => {
//       const nearBottom =
//         window.innerHeight + window.scrollY >=
//         document.documentElement.scrollHeight - 100;
//       if (nearBottom && !isLoading && hasNext) {
//         loadMore();
//       }
//     };

//     window.addEventListener("scroll", handleScroll);

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, [isLoading, hasNext, loadMore]);

//   const groupVideosByType = (videos: any[]) => {
//     let groupedVideos: any[] = [];
//     let fullVideoBatch: any[] = [];
//     let shortVideoBatch: any[] = [];

//     videos.forEach((video) => {
//       if (video.type === "shorts") {
//         shortVideoBatch.push(video);
//       } else {
//         fullVideoBatch.push(video);
//       }

//       if (fullVideoBatch.length === 10) {
//         groupedVideos.push({ type: "full", data: [...fullVideoBatch] });
//         fullVideoBatch = [];
//       }

//       if (shortVideoBatch.length === 10) {
//         groupedVideos.push({ type: "shorts", data: [...shortVideoBatch] });
//         shortVideoBatch = [];
//       }
//     });

//     // Add remaining videos (agar 10 se kam hon to bhi batch bana do)
//     if (fullVideoBatch.length > 0) {
//       groupedVideos.push({ type: "full", data: [...fullVideoBatch] });
//     }
//     if (shortVideoBatch.length > 0) {
//       groupedVideos.push({ type: "shorts", data: [...shortVideoBatch] });
//     }

//     return groupedVideos;
//   };

//   const groupedVideos = groupVideosByType(mergedList);

//   return (
//     <Container>
//       <SuggestionsTab
//         selectedCategory={selectedCategory}
//         setSelectedCategory={setSelectedCategory}
//       />

//       {/* {groupedVideos.map((group, index) =>
//         group.type === "shorts" ? (
//           <ShortVideosCard
//             key={`shorts-${index}`}
//             data={group.data}
//             onVideoClick={(videoId: string, channelID?: string) =>
//               navigate(`${PAGE_ROUTES.SHORT_VIDEO_PAGE}/channel/${channelID}/${videoId}`)
//             }
//           />
//         ) : (
//           <VideosCard
//             key={`videos-${index}`}
//             isLoading={isLoading}
//             data={group.data}
//             loadMore={loadMore}
//           />
//         )
//       )} */}

//       <VideosCard
//         key={`videos`}
//         isLoading={isLoading}
//         data={videos}
//         loadMore={loadMore}
//       />
//       <ShortVideosCard
//         key={`shorts`}
//         data={shortVideos}
//         onVideoClick={(videoId: string, channelID?: string) =>
//           navigate(`${PAGE_ROUTES.SHORT_VIDEO_PAGE}/channel/${channelID}/${videoId}`)
//         }
//       />

//     </Container>
//   );
// }

export default function Home() {
  const [batches, setBatches] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const [currentLowerLimit, setCurrentLowerLimit] = useState(0);
  const [currentUpperLimit, setCurrentUpperLimit] = useState(9);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const user = useAppSelector((state) => getUser(state));
  const [selectedCategory, setSelectedCategory] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    setCurrentLowerLimit(0);
    setCurrentUpperLimit(videoUpperLimit);

    const apiCall = selectedCategory
      ? getHomePageVideos
      : getHomePageVideosRecommendation;

    const fetchData = async () => {
      try {
        const promises: Promise<any>[] = [
          apiCall({
            lowerLimit: 0,
            upperLimit: videoUpperLimit,
            userID: user?.userID,
            category: selectedCategory,
          }),
        ];

        if (!selectedCategory) {
          promises.push(
            getShortVideosRecommendation({
              lowerLimit: 0,
              upperLimit: videoUpperLimit - 3,
              userID: user?.userID,
              videotype: "short",
            })
          );
        }

        const [fullVideos, shortVideos = []] = await Promise.all(promises);

        const fullVideosRes = fullVideos as unknown as any[];
        const newBatch = { fullVideos, shortVideos };
        setBatches([newBatch]);
        setHasNext(fullVideosRes.length >= videoUpperLimit);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [user?.userID, selectedCategory]);

  const loadMore = async () => {
    if (!isLoading && hasNext) {
      setIsLoading(true);
      setIsLoadingMore(true);
      const newLowerLimit = currentUpperLimit + 1;
      const newUpperLimit = newLowerLimit + videoUpperLimit;

      const shortLowerLimit = (batches.length * 5) + 1;
      const shortUpperLimit = shortLowerLimit + 6;

      setCurrentLowerLimit(newLowerLimit);
      setCurrentUpperLimit(newUpperLimit);

      const apiCall = selectedCategory
        ? getHomePageVideos
        : getHomePageVideosRecommendation;

      try {
        const promises: Promise<any>[] = [
          apiCall({
            lowerLimit: newLowerLimit,
            upperLimit: newUpperLimit,
            userID: user?.userID,
            category: selectedCategory,
          }),
        ];

        if (!selectedCategory) {
          promises.push(
            getShortVideosRecommendation({
              lowerLimit: shortLowerLimit,
              upperLimit: shortUpperLimit,
              userID: user?.userID,
              videotype: "short",
            })
          );
        }

        const [fullVideos, shortVideos = []] = await Promise.all(promises);

        const fullVideosRes = fullVideos as unknown as any[];
        setBatches((prevBatches) => [...prevBatches, { fullVideos, shortVideos }]);
        setHasNext(fullVideosRes.length >= videoUpperLimit);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
        setIsLoadingMore(true);
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const nearBottom =
        window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 100;
      if (nearBottom && !isLoading && hasNext) {
        loadMore();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isLoading, hasNext]);

  return (
    <Container>
      {/* Show loader only when NO videos are loaded */}
      {isLoading && batches.length === 0 && (
        <Box
          position="absolute"
          top="50%"
          left="50%"
          sx={{ transform: "translate(-50%, -50%)" }}
        >
          <CircularProgress sx={{ color: "blue" }} />
        </Box>
      )}
  
      {/* Content will be hidden only if NO videos are there */}
      {!(isLoading && batches.length === 0) && (
        <>
          <SuggestionsTab
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
  
          {batches.map((batch, index) => (
            <div key={`batch-${index}`}>
              {/* Full Videos */}
              <VideosCard
                key={`videos-${index}`}
                isLoading={isLoading} // This will only show skeleton while loading more
                data={batch.fullVideos}
                loadMore={async () => {
                  setIsLoadingMore(true);
                  await loadMore();
                  setIsLoadingMore(false);
                }}
              />
  
              {batch.shortVideos.length > 0 ? (
                <Box sx={{ mt: 2, p: 2, bgcolor: "background.paper", borderRadius: 2 }}>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <img
                      src={logoOnly}
                      alt="Theme Logo"
                      width={30}
                      height={30}
                      style={{ marginRight: 8 }}
                    />
                    <Typography variant="h6" fontWeight="bold">
                      Shorts
                    </Typography>
                  </Box>
  
                  <ShortVideosCard
                    key={`shorts-${index}`}
                    isLoading={isLoading} // Skeleton will show only when loading more
                    data={batch.shortVideos}
                    onVideoClick={(videoId: string, channelID?: string) =>
                      navigate(`${PAGE_ROUTES.SHORT_VIDEO_PAGE}/${videoId}`)
                    }
                  />
                </Box>
              ) : null}
            </div>
          ))}
  
          {/* Loader at bottom when load more is triggered */}
          {isLoadingMore && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <CircularProgress size={30} />
            </Box>
          )}
        </>
      )}
    </Container>
  );  
}