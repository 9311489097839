import { ChannelPayload } from "../../types";
import { VideoWrapper } from "../../types/channel";
import { ApiHelper } from "../heplers/APIHelper";
const publicApiHelper = new ApiHelper({isPublic:true});
const authApiHelper = new ApiHelper();

export const checkTagAvailability = (name: string) => {
  return authApiHelper.get("channel/checkTagAvailability", { name });
};

export const createChannel = (payload: ChannelPayload) => {
  return authApiHelper.post("channel/create", payload);
};

export const updateChannel = (channelID: string, userID:string, payload: ChannelPayload) => {
  const uri = `channel/updateChannel?channelID=${channelID}&userID=${userID}`;
  return authApiHelper.post(uri, payload);
};

export const getChannelByID = (channelID: string, userID: string) => {
  let params: any = {
    channelID,
    userID
  };
  return publicApiHelper.get("channel/getChannelByID", params);
};

export const uploadChannelBackground = (image: File, channelID: string, userID: string) => {
  let data: any = {
    backgroundImg: image,
    channelID,
    userID
  };
  //TODO: FETCH USER ID FROM REDUX STORE AND PASS WITH data
  return authApiHelper.upload("channel/setBackgroundPic", data);
};

export const uploadChannelProfile = (image: File, channelID: string, userID: string) => {
  let data: any = {
    profileImg: image,
    channelID,
    userID
  };
  return authApiHelper.upload("channel/setProfilePic", data);
};

//VIDEO APIS START HERE
export const uploadVideo = (file: any, thumbnailFile: any, videoWrapper: VideoWrapper, onUploadProgress: any, videoType: any) => {
  let vw = new Blob([JSON.stringify(videoWrapper)], {type: "application/json"})
  const wrapper = {
    file: file,
    thumbnail: thumbnailFile,
    videoWrapper: vw
  };
  return authApiHelper.upload(`video/upload?videotype=${videoType}`, wrapper, onUploadProgress);
};

export const getChannelVideos = (channelID: string) => {
  return publicApiHelper.get("video/getChannelVideos", { channelID });
};
