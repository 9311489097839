import React, { useState } from "react";
import {
  Button,
  Container,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Snackbar,
  SnackbarContent,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { signIn, verifyUser } from "./AuthSignIn";
import { useAppDispatch } from "../../store/hooks";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { setUser } from "../../store/reducers/user.reducer";
import { getUserDetails } from "../../services/APIServices/user.api";
import { setMessage } from "../../store/reducers/message.reducer";
import { toast } from "react-toastify";

const UserLogin = (props: any) => {
  const { redirectRequired = false, redirectTo, onSuccess = () => {} } = props;
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    otp: "",
  });
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleLogin = async () => {
    try {
      if (!formData.email || !formData.password) {
        toast.error("Please enter both email and password.");
        return;
      }

      const response = await signIn(formData);
      const accessToken = response.accessToken;
  
      if (accessToken) {
        console.log("Login successful:", accessToken);
        localStorage.setItem("token", accessToken);
        toast.success("Login successful! 🎉");
  
        if (accessToken) {
          onSuccess?.();
        }
        if (response.otpRequired) {
          setShowOTPInput(true);
        }
  
        let user = { ...response, userID: response.id };
        getUserDetails(user?.userID)
          .then((res) => {
            console.log("res :", res, ", user :", user);
            if (user?.userID) dispatch(setUser({ ...res, ...user }));
          })
          .catch(() => {
            dispatch(setUser(user));
          })
          .finally(() => {
            if (redirectRequired || location?.state?.redirectTo || redirectTo) {
              navigate(
                (redirectTo ? redirectTo : location?.state?.redirectTo) ?? "/"
              );
            }
          });
      } else if (response.message) {
        // dispatch(setMessage(response.message));
        setShowOTPInput(true);
        toast.info(response.message); // Info toast for OTP required case
      }
    } catch (error: any) {
      console.error("Login failed:", error);
      const errorMessage = error?.response?.data?.message || "Login failed!";
  
      if (error?.response?.status === 400) {
        toast.error("User not found! Please check your email/password."); // 🔴 400 error case
      } else {
        toast.error(errorMessage); // 🔴 General error case
      }
  
      // dispatch(setMessage(errorMessage));
    }
  };

  const handleOTPVerification = async () => {
    try {
      await verifyUser(formData.email, formData.otp)
        .then((res) => dispatch(setMessage(res.message)))
        .catch((err) => dispatch(setMessage(err?.response?.message)));
      console.log("OTP verified");
      navigate("/");
    } catch (error) {
      console.error("OTP verification failed:", error);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container maxWidth={"sm"}>
      <Stack direction={"column"}>
        <h4 style={{ textAlign: "center" }}>Login</h4>
        <TextField
          variant="outlined"
          label="Email"
          placeholder="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
        {!showOTPInput ? (
          <TextField
            sx={{ marginTop: 2 }}
            variant="outlined"
            label="Password"
            placeholder="Password"
            type={showPassword ? "text" : "password"}
            name="password"
            value={formData.password}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ) : null}
        {showOTPInput && (
          <TextField
            sx={{ marginTop: 2 }}
            variant="outlined"
            label="OTP"
            placeholder="Enter OTP"
            name="otp"
            value={formData.otp}
            onChange={handleChange}
          />
        )}

        <Button
          variant="contained"
          sx={{ marginTop: 2 }}
          onClick={showOTPInput ? handleOTPVerification : handleLogin}
        >
          {showOTPInput ? "Verify OTP" : "Login"}
        </Button>

        <h6 style={{ marginTop: 15, textAlign: "center" }}>
          Don't have an account?{" "}
          <span>
            <a href="usersignup">Signup</a>
          </span>{" "}
          now.
        </h6>
        <h6 style={{ marginTop: 2, textAlign: "center" }}>
          <a href="forgotpassword">Forgot Password?</a>
        </h6>
      </Stack>
      {/* <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContent
          sx={{ backgroundColor: "#f44336" }}
          message={snackbarMessage}
        />
      </Snackbar> */}
    </Container>
  );
};

export default UserLogin;
