import { Send } from "@mui/icons-material";
import { Button, Dialog, Input } from "@mui/material";
import { useState, useEffect } from "react";

const CommentSection = ({ videoId }: { videoId: string }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");

  useEffect(() => {
    if (isOpen) {
      fetchComments();
    }
  }, [isOpen]);

  const fetchComments = async () => {
    try {
      const response = await fetch(`/api/getComments?videoId=${videoId}`);
      const data = await response.json();
      setComments(data);
    } catch (error) {
      console.error("Error fetching comments", error);
    }
  };

  const handleAddComment = async () => {
    if (!newComment.trim()) return;
    try {
      const response = await fetch(`/api/addComment`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ videoId, comment: newComment })
      });
      if (response.ok) {
        fetchComments();
        setNewComment("");
      }
    } catch (error) {
      console.error("Error adding comment", error);
    }
  };

  return (
    <div className="absolute bottom-2 left-2 right-2">
      <Button onClick={() => setIsOpen(true)} className="bg-gray-800 text-white flex items-center">
        {/* <MessageCircle className="w-5 h-5 mr-2" /> Comments */} Comments
      </Button>
      <Dialog open={isOpen} onChange={() => setIsOpen((prev) => !prev)}>
        <div className="fixed bottom-0 left-0 right-0 bg-white p-4 rounded-t-2xl max-h-1/2 overflow-y-auto">
          <div className="flex justify-between items-center border-b pb-2">
            <h2 className="text-lg font-semibold">Comments</h2>
            <Button variant="text" onClick={() => setIsOpen(false)}>Close</Button>
          </div>
          <div className="mt-4 space-y-3">
            {comments.length === 0 ? (
              <p className="text-gray-500 text-center">Be the first to comment!</p>
            ) : (
              comments?.map((comment: any, index) => (
                <div key={index} className="border-b pb-2">
                  <p className="font-semibold">{comment?.username}</p>
                  <p className="text-sm text-gray-700">{comment?.text}</p>
                </div>
              ))
            )}
          </div>
          <div className="mt-4 flex items-center border-t pt-2">
            <Input
              type="text"
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              placeholder="Add a comment"
              className="flex-1 mr-2"
            />
            <Button onClick={handleAddComment} className="bg-blue-500 text-white">
              <Send className="w-5 h-5" />
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default CommentSection;
