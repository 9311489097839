import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../store/hooks";
import { getUser } from "../../store/reducers/user.reducer";
import { Navigate, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { PAGE_ROUTES } from "../../utils/constants";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Tooltip from '@mui/material/Tooltip';
import {
  Button,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Skeleton,
  TextField,
} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import { VideoResponse } from "../../types/medial";
import VideoThumbnail from "../../assets/thumbnail.png";
import { RWebShare } from "react-web-share";
import { Delete, Edit, ReportGmailerrorred } from "@mui/icons-material";
import { isUserChannel } from "../../utils";
import { deleteVideo, getFeedbackStatus, reportVideo } from "../../services/APIServices/videos.api";
import UploadVideo from "../../components/UploadVideo/UploadVideo";

interface VideoData extends VideoResponse {
  src?: string;
  title: string;
  channelName: string;
  creationTime: string;
  views: number;
}

export let sendToAngularIframe: (data: any) => void;

// Initialize the sendToAngularIframe function
sendToAngularIframe = (data) => {
  const iframe = document.getElementById("angularAppIframe") as HTMLIFrameElement;
  if (iframe && iframe.contentWindow) {
    iframe.contentWindow.postMessage(data, "http://localhost:4200");
    console.log("Sent message to Angular iframe:", data);
  } else {
    console.error("Iframe not found or not ready to receive messages.");
  }
};

const Shorts: React.FC = () => {
  const userDetails = useAppSelector((state) => getUser(state));
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [isIframeLoaded, setIframeLoaded] = useState<boolean | null>(null);
  const { verifyAuth } = useAuth();
  const { videoID } = useParams();

  useEffect(() => {
    const iframe = document.getElementById("angularAppIframe") as HTMLIFrameElement;

    const handleMessageFromAngular = (event: MessageEvent) => {
      if (event.origin !== "http://localhost:4200") {
        console.warn("Blocked unauthorized message from:", event.origin);
        return;
      }

      const { action, shareUrl, videoID, signIn, channelID } = event.data;

      if (action === "share" && shareUrl) {
        console.log("Received share URL from Angular:", shareUrl);
        navigator.clipboard.writeText(shareUrl).then(() => {
          alert("Video link copied to clipboard: " + shareUrl);
        });
      }

      if (action === "sign-in") {
        if (!signIn) {
          verifyAuth();
        }
      }

      if (action === "update-url" && videoID) {
        const newUrl = `/shorts?videoID=${videoID}`;
        if (location.pathname + location.search !== newUrl) {
          navigate(newUrl, { replace: true });
        }
      }

      if (action === "handle-channel-click" && channelID) {
        navigate(`${PAGE_ROUTES.CHANNEL_PAGE}/profile/${channelID}`);
      }

      // if (action === "short-in-channel" && channelID) {
      //   const newUrl = `/shorts?videoID=${videoID}&channelID=${channelID}`;
      //   navigate(newUrl, { replace: true });

      //   sendToAngularIframe({ action: "short-in-channel", channelID, videoID  });
      // }
    };

    const handleIframeLoad = () => {
      setIframeLoaded(true);

      const token = localStorage.getItem("token");
      const isLoggedIn = !!token;

      sendToAngularIframe({ action: "token-status", isLoggedIn, userDetails, videoID });

      if (videoID) {

      }
    };

    const handleIframeError = () => {
      setIframeLoaded(false);
      console.error("Failed to load Angular app!");
    };

    window.addEventListener("message", handleMessageFromAngular);

    if (iframe) {
      iframe.addEventListener("load", handleIframeLoad);
      iframe.addEventListener("error", handleIframeError);

      if (iframe.contentWindow) {
        handleIframeLoad();
      }
    }

    return () => {
      window.removeEventListener("message", handleMessageFromAngular);
      if (iframe) {
        iframe.removeEventListener("load", handleIframeLoad);
        iframe.removeEventListener("error", handleIframeError);
      }
    };
  }, [userDetails, videoID]);

  const iframeSrc = videoID
    ? `http://localhost:4200/${videoID}`
    : "http://localhost:4200/";

  return (
    <div
      style={{
        width: "100%",
        height: "85vh",
        margin: 0,
        padding: 0,
        overflow: "hidden",
      }}
    >
      <iframe
        id="angularAppIframe"
        src={iframeSrc}
        style={{
          width: "100%",
          height: "100%",
          border: "none",
          overflow: "hidden",
        }}
        title="Angular App"
      />
      {isIframeLoaded === true && (
        <p style={{ color: "green", textAlign: "center" }}>Angular app loaded successfully!</p>
      )}
      {isIframeLoaded === false && (
        <p style={{ color: "red", textAlign: "center" }}>Failed to load Angular app!</p>
      )}
    </div>
  );
};

export default Shorts;

export function ShortVideosCard({ data, isLoading, onVideoClick }: { data: VideoData[], isLoading: boolean, onVideoClick: (videoId: string, channelID?: string) => void }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuID, setMenuID] = useState<null | string>(null);
  const user = useAppSelector((state) => getUser(state));

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); // State for delete confirmation dialog
  const [videoToDelete, setVideoToDelete] = useState<string>(""); // State to store the video ID to be deleted
  const [editVideoDetails, setEditVideoDetails] = useState<any>(null);
  const [editing, setEditing] = useState(false);

  const [reported, setReported] = useState(false);
  const { verifyAuth, setOnLoginSuccess } = useAuth();
  const [isSpamDialogOpen, setIsSpamDialogOpen] = useState(false);
  const [spamMessage, setSpamMessage] = useState("");


  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, id: string) => {
    setAnchorEl(event.currentTarget);
    setMenuID(id);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const formatNumber = (value: number | undefined, suffix: string): string => {
    if (value === undefined) return `0 ${suffix}`;
    if (value >= 1e9) return `${(value / 1e9).toFixed(1)}B ${suffix}`;
    if (value >= 1e6) return `${(value / 1e6).toFixed(1)}M ${suffix}`;
    if (value >= 1e3) return `${(value / 1e3).toFixed(1)}K ${suffix}`;
    return `${value} ${suffix}`;
  };

  const handleShortVideo = (video: any) => {
    if (video) {
      // Navigate to the shorts page
      // navigate(`${PAGE_ROUTES.SHORT_VIDEO_PAGE}/channel/${video.channelID}/${video.videoID}`);
      onVideoClick(video.videoID, video.channelID || null);
    }
  };

  const handleEdit = (videoDetails: any) => {
    console.log("Edit video");
    setEditVideoDetails(videoDetails);
    setEditing(true);
    handleMenuClose();
  };

  const handleDelete = (videoId: string) => {
    // Open delete confirmation dialog
    setVideoToDelete(videoId);
    setDeleteDialogOpen(true);
  };

  const confirmDelete = () => {
    deleteVideo(videoToDelete).then((res: any) => {
      console.log(" deleteVideo : ", res);
      // setVideos(videos.filter((video) => video.videoID !== videoToDelete));
      navigate(0);
    }, (error: any) => {
      console.log("Failed to delete video:", error);
    });
    // Close delete confirmation dialog
    setDeleteDialogOpen(false);
  };

  const fetchVideoDetails = (videoID: any) => {
    if (user?.userID) {
      try {
        getFeedbackStatus(user.userID, videoID).then(
          (feedbackStatus: any) => {
            setReported(feedbackStatus?.spam);
          }
        );
      } catch (error) {
        console.error("Error fetching video details or feedback status:", error);
      }
    }
  };

  const handleReport = (videoID: any) => {
    reportVideo(user?.userID as string, videoID as string, spamMessage).then(
      (res: any) => {
        fetchVideoDetails(videoID);
        setSpamMessage(""); // Clear the input
        setIsSpamDialogOpen(false); // Close the popup
      }
    );
  };
  const handleSpamClick = (videoID: any) => {
    fetchVideoDetails(videoID);
    if (verifyAuth()) {
      setIsSpamDialogOpen(true);
    } else {
      setOnLoginSuccess(() => setIsSpamDialogOpen(true));
    }
  };


  return (
    <Grid container spacing={2}>
      {data.map((video, index) => (
        <Grid item xs={6} sm={4} lg={2} key={index}>
          <Card sx={{ position: "relative", overflow: "hidden" }}>
            {!isLoading ? (
              <>
                {/* Thumbnail */}
                <CardMedia
                  component="img"
                  sx={{
                    width: "100%",
                    aspectRatio: "9/16",
                    objectFit: "cover",
                  }}
                  alt={video?.title}
                  image={video?.thumbnailPic ?? VideoThumbnail}
                  onClick={() => handleShortVideo(video)}
                />
              </>
            ) : (
              /* Skeleton Loader */
              <Skeleton
                width="100%"
                sx={{
                  aspectRatio: "9/16",
                  bgcolor: "rgba(255, 255, 255, 0.12)",
                  borderRadius: "10px",
                  animation: "pulse 1.5s infinite ease-in-out",
                }}
              />
            )}

            {/* Title + Views Section */}
            <Box sx={{ p: 1 }}>
              {/* Title - Max 2 Lines */}
              {isLoading ? (
              <Skeleton variant="text" width="100%" height={20} />
            ) : (
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{
                  display: "-webkit-box",
                  WebkitLineClamp: 2,  // Max 2 lines
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: "14px",
                  minHeight: "40px", // Ensures space even for single-line titles
                }}
              >
                {video?.title}
              </Typography>
            )}

              {/* Views - Fixed Height */}
              {isLoading ? (
              <Skeleton variant="text" width="60%" height={15} />
            ) : (
              <Typography
                variant="caption"
                color="gray"
                sx={{ display: "block", minHeight: "18px" }} // Fixed height for views
              >
                {formatNumber(video?.views, "views")}
              </Typography>
            )}
            </Box>

            {/* Options Icon - More Right Side */}
            <Box sx={{ position: "absolute", bottom: 15, right: 5 }}>
              <Tooltip title="More Options" arrow>
                <IconButton
                  aria-label="more"
                  aria-controls={`video-menu-${index}`}
                  aria-haspopup="true"
                  onClick={(e) => handleMenuOpen(e, `video-menu-${index}`)}
                  color="inherit"
                  sx={{ color: "black", padding: "5px" }}
                >
                  <MoreVertIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Card>

          <Menu
            id={`video-menu-${index}`}
            anchorEl={anchorEl}
            open={Boolean(anchorEl) && menuID === `video-menu-${index}`}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            PaperProps={{
              style: {
                width: "250px",
              },
            }}
          >
            <RWebShare
              data={{
                text: video?.videoID,
                url: `${window.location.origin}/shorts/share/${video?.videoID}`,
                title: video?.title,
              }}
            >
              <MenuItem>
                <ListItemIcon>
                  <ShareIcon fontSize="small" />
                </ListItemIcon>
                Share
              </MenuItem>
            </RWebShare>

            {/* Condition to check if user uploaded the video */}
            {isUserChannel(user?.channelList, video?.channelID) ? (
              <>
                <MenuItem onClick={() => handleEdit(video)}>
                  <ListItemIcon>
                    <Edit fontSize="small" />
                  </ListItemIcon>
                  Edit
                </MenuItem>
                <MenuItem onClick={() => handleDelete(video.videoID)}>
                  <ListItemIcon>
                    <Delete fontSize="small" />
                  </ListItemIcon>
                  Delete
                </MenuItem>
              </>
            ) : (
              <>
                <Divider />
                <MenuItem onClick={() => handleSpamClick(video.videoID)}>
                  <ListItemIcon>
                    <ReportGmailerrorred fontSize="small" />
                  </ListItemIcon>
                  Spam
                </MenuItem>
              </>
            )}



            <Dialog open={isSpamDialogOpen} onClose={() => setIsSpamDialogOpen(false)}>
              <DialogTitle>{reported ? "Remove Spam" : "Report Spam"}</DialogTitle>
              <DialogContent>
                {reported ? (
                  <Typography>Do you want to remove the spam report?</Typography>
                ) : (
                  <TextField
                    label="Enter your reason for reporting"
                    fullWidth
                    multiline
                    rows={4}
                    value={spamMessage}
                    onChange={(e) => setSpamMessage(e.target.value)}
                  />
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setIsSpamDialogOpen(false)}>Cancel</Button>
                <Button
                  onClick={() => handleReport(video.videoID)}
                  color="primary"
                  disabled={!reported && !spamMessage.trim()} // Disable if no message when reporting
                >
                  {reported ? "Remove" : "Submit"}
                </Button>
              </DialogActions>
            </Dialog>
          </Menu>
        </Grid>
      ))}

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this video?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="primary">
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
      {editing ? (
        <UploadVideo
          defaultOpen
          onModalClose={() => setEditing(false)}
          width={"80vw"}
          isEditing
          details={editVideoDetails}
          onEditSuccess={() => setEditing(false)}
        />
      ) : null}
    </Grid>
  );




  return (
    <Grid container spacing={2}>
      {data.map((video, index) => (
        <Grid item xs={6} sm={4} lg={2} key={index}>
          <Card sx={{ position: "relative", overflow: "hidden" }}>
            {!isLoading ? (
              <>
                {/* Thumbnail */}
                <CardMedia
                  component="img"
                  sx={{
                    width: "100%",
                    aspectRatio: "9/16",
                    objectFit: "cover",
                  }}
                  alt={video?.title}
                  image={video?.thumbnailPic ?? VideoThumbnail}
                  onClick={() => handleShortVideo(video)}
                />
              </>
            ) : (
              /* Skeleton Loader */
              <Skeleton
                width="100%"
                sx={{
                  aspectRatio: "9/16",
                  bgcolor: "rgba(255, 255, 255, 0.12)",
                  borderRadius: "10px",
                  animation: "pulse 1.5s infinite ease-in-out",
                }}
              />
            )}

            {/* Title + Views Section */}
            <Box sx={{ p: 1 }}>
              {/* Title - Max 2 Lines */}
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{
                  display: "-webkit-box",
                  WebkitLineClamp: 2,  // Max 2 lines
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: "14px",
                  minHeight: "40px", // Ensures space even for single-line titles
                }}
              >
                {video?.title}
              </Typography>

              {/* Views - Fixed Height */}
              <Typography
                variant="caption"
                color="gray"
                sx={{ display: "block", minHeight: "18px" }} // Fixed height for views
              >
                {formatNumber(video?.views, "views")}
              </Typography>
            </Box>

            {/* Options Icon - More Right Side */}
            <Box sx={{ position: "absolute", bottom: 15, right: 5 }}>
              <Tooltip title="More Options" arrow>
                <IconButton
                  aria-label="more"
                  aria-controls={`video-menu-${index}`}
                  aria-haspopup="true"
                  onClick={(e) => handleMenuOpen(e, `video-menu-${index}`)}
                  color="inherit"
                  sx={{ color: "white", padding: "5px" }}
                >
                  <MoreVertIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Card>
        </Grid>
      ))}
    </Grid>

  );
}