import { Container } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Banner from "../../components/banner";
import ProfileCard from "../../components/channel-profile-card";
import TabBar from "../../components/TabBar/TabBar";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getChannel, setChannel } from "../../store/reducers/channel.reducer";
import {
  checkTagAvailability,
  createChannel,
  getChannelByID,
  getChannelVideos,
  updateChannel,
  uploadChannelBackground,
  uploadChannelProfile,
} from "../../services/APIServices/channel.api";
import { useLocation, useNavigate } from "react-router-dom";
import { PAGE_ROUTES, ROUTES_PATH } from "../../utils/constants";
import { ChannelDetails, ChannelPayload } from "../../types/channel";
import { getUser } from "../../store/reducers/user.reducer";
import {
  getUserChannelVideos,
  setChannelVideos,
} from "../../store/reducers/channelVideos.reducer";
import { isMobile } from "react-device-detect";
import { isUserChannel } from "../../utils";

interface ChannelProps {
  create?: boolean;
}

export default function Channel({ create }: ChannelProps) {
  const [loading, setLoading] = useState(true);
  const [isCreate, setIsCreate] = useState(create);
  const [isEditing, setIsEditing] = useState(create);
  const [isValidTag, setIsValidTag] = useState(true);
  const [isExpanded, setExpanded] = useState(false);
  let dispatch = useAppDispatch();
  let channel = useAppSelector((state) => getChannel(state));
  let user = useAppSelector((state) => getUser(state));
  let backgroundImgRef = useRef<File | null>(null);
  let tagCheckReq = useRef<any>(null);
  let videos = useAppSelector((state) => getUserChannelVideos(state));
  let { pathname } = useLocation();
  let navigate = useNavigate();
  const [viewOnly, setViewOnly] = useState(true);
  useEffect(() => {
    if (user?.userID) {
      if (user?.channelList?.length === 0) {
        navigate(ROUTES_PATH.CREATE_CHANNEL);
      }
      setViewOnly(isUserChannel(user?.channelList, channel?.channelID));
    } else {
      navigate(ROUTES_PATH.LOGIN, {
        state: {
          redirectTo: ROUTES_PATH?.CHANNEL_LANDING,
        },
      });
    }
  }, [user]);

  // useEffect(() => {
  //   if (pathname === ROUTES_PATH.CREATE_CHANNEL) {
  //     setIsCreate(true);
  //     setLoading(false);
  //   } else {
  //     setIsCreate(false);
  //     if (pathname === ROUTES_PATH.CHANNEL_LANDING && user?.channelList?.[0]) {
  //       getChannelByID(user?.channelList?.[0]?.channelID, user?.userID).then(
  //         (data) => dispatch(setChannel(data))
  //       );
  //     }
  //   }
  // }, [pathname, user, dispatch]);

  useEffect(() => {
    if (pathname === ROUTES_PATH.CREATE_CHANNEL) {
      setIsCreate(true);
      setLoading(false);
    } else if (!isCreate && user?.channelList?.[0]) {
      getChannelByID(user.channelList[0].channelID, user.userID).then((data) => {
        dispatch(setChannel(data));
      });
    }
  }, [pathname, user, isCreate, dispatch]); // extra dependencies remove kar di

  useEffect(() => {
    if (channel) {
      setViewOnly(isUserChannel(user?.channelList, channel?.channelID));
      getChannelVideos(channel?.channelID).then((data) =>
        dispatch(setChannelVideos(data))
      );
    }
  }, [channel]);

  const getLoadingStatus = useCallback(
    () => (channel || isCreate ? false : true),
    [channel, isCreate]
  );

  useEffect(() => {
    setLoading(getLoadingStatus);
  }, [channel, getLoadingStatus]);

  const handleBackgroundImage = (file: File) => {
    backgroundImgRef.current = file;
    setIsEditing(true);
  };
  const handleProfileImage = (file: File, channelID?: string) => {
    uploadChannelProfile(
      file as File,
      channelID ?? channel.channelID,
      user?.userID
    )
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
  };
  const handleProfileChange = (payload: any) => {
    if (backgroundImgRef.current) {
      uploadChannelBackground(
        backgroundImgRef.current as File,
        `${channel.channelID}`,
        user?.userID
      )
        .then((res) => console.log(res))
        .catch((err) => console.error(err));
    }
    updateChannel(channel?.channelID, user?.userID, payload)
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    setIsEditing(false);
  };
  const handleTagChange = (tag: string) => {
    if (tagCheckReq.current) clearTimeout(tagCheckReq.current);

    tagCheckReq.current = setTimeout(() => {
      checkTagAvailability(tag)
        .then((data: any) => {
          if (data.status === "AVAILABLE" || tag === channel?.channelTag) {
            setIsValidTag(true);
          } else {
            setIsValidTag(false);
          }
        })
        .catch((err) => {
          console.error("Tag check error:", err);
          setIsValidTag(false);
        });
    }, 600);
  };

  const handleVideoUpload = () => {
    if (channel) {
      getChannelVideos(channel?.channelID).then((data) =>
        dispatch(setChannelVideos(data))
      );
    }
  };

  const handleCreateChannel = (channelDetails: ChannelDetails) => {
    if (channelDetails?.channelName) {
      let channelPayload: ChannelPayload = {
        channelName: channelDetails.channelName,
        channelTag: channelDetails.channelTag,
        categories: channelDetails?.categories
          ? channelDetails?.categories
          : [],
        channelDescription: channelDetails?.channelDescription ?? "",
        contactDetails: channelDetails?.contactDetails ?? "",
        userID: user?.userID,
      };
      createChannel(channelPayload).then((res) => {
        console.log(res);
        if (res) {
          let ch: any = res;
          if (ch?.channelID) {
            handleProfileImage(
              channelDetails.profilePic as File,
              ch?.channelID
            );
            if (backgroundImgRef.current) {
              uploadChannelBackground(
                backgroundImgRef.current as File,
                ch?.channelID,
                user?.userID
              )
                .then(() => {
                  console.log("Background uploaded successfully.");
                })
                .catch((err) => console.error(err));
            }

            // Refresh the channel list in the state
            getChannelByID(ch?.channelID, user?.userID).then((data) => {
              dispatch(setChannel(data)); // Update the channel in the Redux state
              setIsCreate(false); // Exit creation mode
              setLoading(false);
              navigate(PAGE_ROUTES.CHANNEL_PAGE)
              navigate(0);
            });
          }
        }
      });
    }
  };

  return (
    <div style={{ paddingLeft: isMobile ? 0 : "16px" }}>
      <Banner
        defaultImage={channel?.backgroundPic}
        isLoading={loading}
        handleUploadImage={handleBackgroundImage}
      />
      <ProfileCard
        channel={channel}
        isLoading={loading}
        expanded={isCreate}
        edited={isEditing}
        handleProfileImage={handleProfileImage}
        handleProfileChange={handleProfileChange}
        handleCreateChannel={handleCreateChannel}
        handleTagChange={handleTagChange}
        handleExpansion={(v: boolean) => setExpanded(v)}
        isValidTag={isValidTag}
      />
      {isCreate || isExpanded ? null : (
        <TabBar
          viewOnly={!viewOnly}
          data={videos}
          isLoading={loading}
          handleUploadVideo={handleVideoUpload}
        />
      )}
    </div>
  );
}
