// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.player-wrapper {
    width: auto;
    height: auto;
}

.react-player {
    width: 100%;
    aspect-ratio: 16/9;
}

.absolute {
    right: 0 !important;
    left: 0 !important;
}

.player-placeholder {
    position: relative;
    width: 100%;
    padding-bottom: 58.25%;
    overflow: hidden;
}

.video-container:fullscreen {
    width: 100vw;
    height: 100vh;
    background: black;
    position: relative;
    overflow: hidden;
}

.video-container {
    position: relative;
    overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/components/Player/VideoPlayer.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":[".player-wrapper {\r\n    width: auto;\r\n    height: auto;\r\n}\r\n\r\n.react-player {\r\n    width: 100%;\r\n    aspect-ratio: 16/9;\r\n}\r\n\r\n.absolute {\r\n    right: 0 !important;\r\n    left: 0 !important;\r\n}\r\n\r\n.player-placeholder {\r\n    position: relative;\r\n    width: 100%;\r\n    padding-bottom: 58.25%;\r\n    overflow: hidden;\r\n}\r\n\r\n.video-container:fullscreen {\r\n    width: 100vw;\r\n    height: 100vh;\r\n    background: black;\r\n    position: relative;\r\n    overflow: hidden;\r\n}\r\n\r\n.video-container {\r\n    position: relative;\r\n    overflow: hidden;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
