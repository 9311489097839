import React, { useState } from 'react';
import axios from 'axios';
import { Button, Container, Stack, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const VerifyUser = () => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const navigate = useNavigate();

  const handleVerifyUser = async () => {
    try {
      const response = await axios.post('http://35.200.132.174:2024/api/rest/user/verifyUser', {
        email: email,
        otp: otp,
      });

      console.log('Verify User successful:', response.data);
      navigate("/");

    } catch (error) {
      console.error('Verify User failed:', error);
    }
  };

  return (
    <Container maxWidth={'sm'}>
      <Stack direction={'column'}>
        <h4 style={{ textAlign: 'center' }}>Verify User</h4>
        <TextField
          variant="outlined"
          label="Email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          sx={{ marginTop: 2 }}
          variant="outlined"
          label="OTP"
          placeholder="OTP"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
        />
        <Button variant="contained" sx={{ marginTop: 2 }} onClick={handleVerifyUser}>
          Verify User
        </Button>
        <h6 style={{ marginTop: 10, textAlign: 'center' }}>
          Don't have an account? <span><a href="usersignup">Signup</a></span> now.
        </h6>
      </Stack>
    </Container>
  );
};

export default VerifyUser;
