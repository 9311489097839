import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useAppSelector } from "../../store/hooks";
import { useNavigate } from "react-router-dom";
import { getUser } from "../../store/reducers/user.reducer";
import axios from "axios";
import { addComments, addReply, dislikeVideo, getComments, getFeedbackStatus, getReplies, getVideoDetailsByID, likeVideo, reportVideo } from "../../services/APIServices/videos.api";
import { getChannelByID, getChannelVideos } from "../../services/APIServices/channel.api";
import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Skeleton, Slider, Stack, TextField, Typography } from "@mui/material";
import { Send, MoreVert, Pause, PlayArrow, Share, ThumbDown, ThumbUp, VolumeOff, VolumeUp, Close, Info, Settings, Speed, Report, Check } from "@mui/icons-material";
import CommentIcon from '@mui/icons-material/Comment';
import { getUserDetails, inscribeChannel } from "../../services/APIServices/user.api";
import { verifyAuth } from "../../services";
import { useAuth } from "../../context/AuthContext";
import { isUserChannel } from "../../utils";
import { PAGE_ROUTES } from "../../utils/constants";
import { RWebShare } from "react-web-share";
import CommentSection from "./CommentSection";
import moment from "moment";
import { colors } from "../../theme";
const baseURL = process.env.REACT_APP_SERVER_URL;

const Short = forwardRef(({ short, shortContainerRef, index, setCurrentVideoIndex, isActive, currentVideoIndex }, ref) => {
  const playPauseRef = useRef();
  const videoRef = useRef();
  let videoID = short.videoID;
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const token = localStorage.getItem('token');

  const [videoDetails, setVideoDetails] = useState(null);
  const [channel, setChannel] = useState(null);
  const [loading, setLoading] = useState(false);
  const [channelVideos, setChannelVideos] = useState(null);
  const user = useAppSelector((state) => getUser(state));
  const navigate = useNavigate();
  const [cancelToken, setCancelToken] = useState(null);
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);
  const [reported, setReported] = useState(false);
  const { verifyAuth, setOnLoginSuccess } = useAuth();
  const [isSpamDialogOpen, setIsSpamDialogOpen] = useState(false);
  const [spamMessage, setSpamMessage] = useState("");
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isCommentOpen, setIsCommentOpen] = useState(false);
  const [usersDetails, setUsersDetails] = useState({});
  const [selectedQuality, setSelectedQuality] = useState("Medium");
  const [comments, setComments] = useState([]);
  const [replyInput, setReplyInput] = useState(null);
  const [commentInput, setCommentInput] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [replies, setReplies] = useState([]);
  const inputRef = useRef(null);
  const currentVideoIndexRef = useRef(0);
  const [isQualityMenuOpen, setIsQualityMenuOpen] = useState(false);
  const [isGlobalMuted, setIsGlobalMuted] = useState(false);

  const availableQualities = ["Low", "Medium", "High"]; // Quality Options
  const [isDescriptionMenuOpen, setIsDescriptionMenuOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    getCurrentVideoIndex: () => currentVideoIndexRef.current,
    setCurrentVideoIndex: (index) => {
      currentVideoIndexRef.current = index;
    },
  }));

  useEffect(() => {
    if (index === 0 && short.videoID && videoRef?.current) {
      videoRef?.current.play().catch((err) => console.error("Play error:", err));
      setIsPlaying(true);
    }

    if (index === currentVideoIndexRef.current) {
      const videoID = short.videoID;

      if (videoID && videoDetails?.videoID !== videoID) {
        const cancelTokenSource = axios.CancelToken.source();
        setCancelToken(cancelTokenSource);

        getVideoDetailsByID(videoID, cancelTokenSource.token).then((res) => {
          setVideoDetails(res);
          getChannelByID(res?.channelID, user?.userID).then((res) => {
            setChannel(res);
          });
        });
      }
    }

    return () => {
      setChannel(null);
      setVideoDetails(null);
      if (cancelToken) {
        cancelToken.cancel("Request cancelled");
      }
    };
  }, [index, currentVideoIndexRef.current, short.videoID]);


  useEffect(() => {
    if (index === currentVideoIndexRef.current) {
      fetchVideoDetails();
    }
  }, [index, currentVideoIndexRef.current, short.videoID]);  // ✅ Ensuring only for active video

  const fetchVideoDetails = async () => {
    if (index === currentVideoIndexRef.current && user?.userID && short.videoID) {
      try {
        const videoDetails = await getVideoDetailsByID(short.videoID);
        setVideoDetails(videoDetails);

        getFeedbackStatus(user.userID, short.videoID).then((feedbackStatus) => {
          setLiked(feedbackStatus.like || false);
          setDisliked(feedbackStatus.dislike || false);
          setReported(feedbackStatus.spam || false);
        });
      } catch (error) {
        console.error("Error fetching video details or feedback status:", error);
      }
    }
  };


  useEffect(() => {
    setLoading(true);
    if (videoDetails !== null && channel !== null) {
      setLoading(false);
    }
  }, [videoDetails]);

  const handleInscribe = () => {
    inscribeChannel(channel?.channelID, user?.userID).then(
      (res) => {
        let status = res?.message?.split(" ")?.[0];
        if (status && channel) {
          getChannelByID(videoDetails?.channelID, user?.userID).then(
            (res) => {
              setChannel(res);
              setLoading(false);
            }
          );
        }
      }
    );
  };

  const handleSubscribeClick = () => {
    if (verifyAuth()) {
      handleInscribe();
    } else {
      setOnLoginSuccess(handleInscribe);
    }
  };

  const handleDislike = () => {
    dislikeVideo(user?.userID, videoDetails?.videoID).then(
      (res) => {
        fetchVideoDetails();
        getVideoDetailsByID(videoDetails?.videoID).then(
          (res) => {
            setVideoDetails(res);
          }
        );
      }
    );
  };
  const handleDislikeClick = () => {
    if (verifyAuth()) {
      handleDislike();
    } else {
      setOnLoginSuccess(handleDislike);
    }
  };
  const handleReport = () => {
    reportVideo(user?.userID, videoDetails?.videoID, spamMessage).then(
      (res) => {
        fetchVideoDetails();
        getVideoDetailsByID(videoDetails?.videoID).then((res) => {
          setVideoDetails(res);
          setSpamMessage(""); // Clear the input
          setIsSpamDialogOpen(false); // Close the popup
        });
      }
    );
  };
  const handleSpamClick = () => {
    if (verifyAuth()) {
      setIsSpamDialogOpen(true);
    } else {
      setOnLoginSuccess(() => setIsSpamDialogOpen(true));
    }
  };

  const handleLike = () => {
    likeVideo(user?.userID, videoDetails?.videoID).then(
      (res) => {
        fetchVideoDetails();
        getVideoDetailsByID(videoDetails?.videoID).then(
          (res) => {
            setVideoDetails(res);
          }
        );
      }
    );
  };
  const handleLikeClick = () => {
    if (verifyAuth()) {
      handleLike();
    } else {
      setOnLoginSuccess(handleLike);
    }
  };

  const handleChannelClick = () => {
    if (channel?.channelID) {
      navigate(`${PAGE_ROUTES.CHANNEL_PAGE}/profile/${channel?.channelID}`);
    }
  };

  const formatNumber = (value, suffix) => {
    if (value === undefined) return `0 ${suffix}`;
    if (value >= 1e9) return `${(value / 1e9).toFixed(1)}B ${suffix}`;
    if (value >= 1e6) return `${(value / 1e6).toFixed(1)}M ${suffix}`;
    if (value >= 1e3) return `${(value / 1e3).toFixed(1)}K ${suffix}`;
    return `${value} ${suffix}`;
  }

  useEffect(() => {
    // Interval to update current time and duration for short video
    const interval = setInterval(() => {
      if (videoRef.current) {
        const current = videoRef.current.currentTime; // For short video, use videoRef.currentTime
        const total = videoRef.current.duration; // For short video, use videoRef.duration
        setCurrentTime(current);
        setDuration(total);
      }
    }, 500); // Update every 500ms
    return () => clearInterval(interval); // Cleanup interval
  }, []);

  const handleQualityChange = (q) => {
    if (videoRef.current) {
      const current = videoRef.current.currentTime; // Get current time for short video
      setCurrentTime(current); // Set current time to state

      setSelectedQuality(q); // Change quality
      setIsQualityMenuOpen(false);
      setTimeout(() => {
        if (videoRef.current) {
          videoRef.current.currentTime = current;
          videoRef?.current.play().catch((err) => console.error("Play error:", err));
        }
      }, 50); // Short delay before seeking
    }
  };

  useEffect(() => {
    if (videoID && index === currentVideoIndexRef.current) {
      getComments(videoID, 0, 10).then(async (res) => {
        if (!res) return;

        // ✅ Fetch user details for each comment and update response
        const updatedComments = await Promise.all(
          res.map(async (comment) => {
            let userRes = null;
            if (user?.userID) {
              userRes = await getUserDetails(comment.userID);
            }
            return {
              ...comment,
              profilePic: userRes?.profilePic || "", // ✅ Add profilePic field
              formattedTime: new Date(comment.creationTime).toLocaleString(), // ✅ Format timestamp
            };
          })
        );

        setComments(updatedComments); // ✅ Set updated comments
      });
    }
  }, [index, currentVideoIndexRef.current, videoID]);

  const handleComment = () => {
    if (commentInput && videoID) {
      if (replyView) {
        addReply(commentInput, user?.userID, videoID, replyView?.commentID).then(() => {
          getReplies(replyView?.commentID, 0, 10).then(async (res) => {
            // ✅ Fetch user details for each reply and update the response
            const updatedReplies = await Promise.all(
              res.map(async (reply) => {
                let userRes = null;
                if (user?.userID) {
                  userRes = await getUserDetails(reply.userID);
                }

                return {
                  ...reply,
                  profilePic: userRes?.profilePic || "", // ✅ Add profilePic field
                  formattedTime: new Date(reply.creationTime).toLocaleString(), // ✅ Format timestamp
                };
              })
            );
            setReplies(updatedReplies);
            // setReplies((prev) => ({ ...prev, [replyView?.commentID]: updatedReplies }));
          });
        });
      } else {
        addComments(commentInput, user?.userID, videoID).then(() => {
          getComments(videoID, 0, 10).then(async (res) => {
            // ✅ Fetch user details for each new comment and update response
            const updatedComments = await Promise.all(
              res.map(async (comment) => {
                let userRes = null;
                if (user?.userID) {
                  userRes = await getUserDetails(comment.userID);
                }
                return {
                  ...comment,
                  profilePic: userRes?.profilePic || "", // ✅ Add profilePic field
                  formattedTime: new Date(comment.creationTime).toLocaleString(), // ✅ Format timestamp
                };
              })
            );

            setComments(updatedComments); // ✅ Set updated comments
          });
        });
      }
      setCommentInput("");
      setReplyInput([]);
    }
  };

  const [replyView, setReplyView] = useState(null);

  const fetchReplies = async (commentID) => {
    try {
      const res = await getReplies(commentID);
      const updatedReplies = await Promise.all(
        res.map(async (reply) => {
          let userRes = null;
          if (user?.userID) {
            userRes = await getUserDetails(reply.userID);
          }
          return {
            ...reply,
            profilePic: userRes?.profilePic || "", // Add profilePic
            formattedTime: new Date(reply.creationTime).toLocaleString(), // Format time
          };
        })
      );
      setReplies(updatedReplies);
    } catch (error) {
      console.error("Error fetching replies:", error);
    }
  };

  useEffect(() => {
    shortContainerRef.current.addEventListener("scroll", handleVideo);
    setIsPlaying(!videoRef.current.paused);
    setIsMuted(isGlobalMuted);
    window.addEventListener("blur", () => {
      if (isActiveVideo(videoRef)) {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    });

    window.addEventListener("focus", () => {
      if (isActiveVideo(videoRef)) {
        videoRef?.current.play().catch((err) => console.error("Play error:", err));
        setIsPlaying(true);
      }
    });
  }, [shortContainerRef, isGlobalMuted]);

  async function handleVideo() {
    if (!videoRef.current) {
      return;
    }

    const videoTop = videoRef.current.getBoundingClientRect().top;

    if (videoTop > 0 && videoTop <= 150) {
      try {
        await videoRef?.current.play().catch((err) => console.error("Play error:", err));
        setIsPlaying(true);
        currentVideoIndexRef.current = index;
        videoRef.current.muted = isGlobalMuted;
        setIsMuted(isGlobalMuted);
      } catch (error) {
        console.error("Error playing video:", error);
        setIsPlaying(false);
        videoRef.current.pause();
      }
    } else {
      videoRef.current.currentTime = 0;
      videoRef.current.pause();
    }
  }

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setComments([...comments]);
      }, 100);
    }
  }, [isOpen]);

  const commentBoxRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (commentBoxRef.current && !commentBoxRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isOpen]);

  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const optionsRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        setIsOptionsOpen(false); // Close options popup
      }
    }

    if (isOptionsOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isOptionsOpen]);

  const speedOptions = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]; // Available speeds

  const [isSpeedMenuOpen, setIsSpeedMenuOpen] = useState(false);
  const [selectedSpeed, setSelectedSpeed] = useState(1); // Default Speed 1x

  const speedRef = useRef(null);
  const qualityMenuRef = useRef(null);

  // Close popup on outside click
  useEffect(() => {
    function handleClickOutside(event) {
      if (speedRef.current && !speedRef.current.contains(event.target)) {
        setIsOptionsOpen(false);
        setIsSpeedMenuOpen(false);
      }
    }

    if (isOptionsOpen || isSpeedMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isOptionsOpen, isSpeedMenuOpen]);

  // Close popup on outside click
  useEffect(() => {
    function handleClickOutside(event) {
      if (qualityMenuRef.current && !qualityMenuRef.current.contains(event.target)) {
        setIsOptionsOpen(false);
        setIsQualityMenuOpen(false);
      }
    }

    if (isOptionsOpen || isQualityMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isOptionsOpen, isQualityMenuOpen]);

  const descriptionMenuRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (descriptionMenuRef.current && !descriptionMenuRef.current.contains(event.target)) {
        setIsOptionsOpen(false);
        setIsDescriptionMenuOpen(false);
      }
    }

    if (isOptionsOpen || isDescriptionMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isOptionsOpen, isDescriptionMenuOpen]);


  const handleSpeedChange = (speed) => {
    setSelectedSpeed(speed);
    if (videoRef.current) {
      videoRef.current.playbackRate = speed; // Update video playback speed
    }
    setIsSpeedMenuOpen(false); // Close speed menu
  };


  return (
    <div ref={ref} className={`reel`}>
      <div className={`reel-video ${isActive ? 'active' : ''}`}>
        <div className="video">
          {/* <div className="video-con"> */}
          {/* {loading ? (
          <Skeleton variant="rectangular" width="100%" height="auto" sx={{ borderRadius: 2 }} /> // Skeleton for video
        ) : ( */}
          <video
            ref={videoRef}
            onClick={function (e) {
              if (!isPlaying) {
                videoRef?.current.play().catch((err) => console.error("Play error:", err));
                setIsPlaying(true);
              } else {
                videoRef?.current.pause();
                setIsPlaying(false);
              }
            }}
            playback={selectedSpeed}
            playsInline
            loop
            muted={isMuted}
            src={`${baseURL}video/getVideo?watch=${short.videoID}&quality=${selectedQuality.toLowerCase()}`}
          ></video>
          {/* )} */}
          {/* </div> */}
          <div className="controls">

            <IconButton
              onClick={() => {
                if (!isPlaying) {
                  videoRef?.current.play().catch((err) => console.error("Play error:", err));
                  setIsPlaying(true);
                } else {
                  videoRef.current.pause();
                  setIsPlaying(false);
                }
              }}
            >
              {isPlaying ? <Pause /> : <PlayArrow />}
            </IconButton>

            <IconButton
              onClick={() => {
                setIsGlobalMuted((prevMuted) => {
                  const newMuteState = !prevMuted;
                  return newMuteState;
                });

                setTimeout(() => {
                  document.querySelectorAll("video").forEach((vid) => {
                    vid.muted = !isGlobalMuted;
                  });
                }, 0); // ✅ Ensure videos update after state change
              }}
            >
              {isMuted ? <VolumeOff /> : <VolumeUp />}
            </IconButton>
          </div>
          <div
            ref={playPauseRef}
            onClick={() => {
              videoRef?.current.play().catch((err) => console.error("Play error:", err));
              setIsPlaying(true);
            }}
            className={`play-pause ${isPlaying ? "" : "show-play-pause"}`}
          >
            <PlayArrow />
          </div>
          <div className="reaction">
            <div>
              <IconButton
                onClick={handleLikeClick}
                disabled={isUserChannel(user?.channelList, channel?.channelID)}
                sx={{
                  color: liked ? "#027fff" : "white",
                  "&.Mui-disabled": {
                    color: "white",
                  },
                }}
              >
                <ThumbUp />
              </IconButton>
              <Typography sx={{ fontSize: "10px", color: "#fff", textAlign: "center" }}>
                {formatNumber(videoDetails?.likes, "")}
              </Typography>
            </div>

            {/* Dislike Button */}
            <div>
              <IconButton
                onClick={handleDislikeClick}
                disabled={isUserChannel(user?.channelList, channel?.channelID)}
                sx={{
                  color: disliked ? "#027fff" : "white",
                  "&.Mui-disabled": {
                    color: "white",
                  },
                }}
              >
                <ThumbDown />
              </IconButton>
              <Typography sx={{ fontSize: "10px", color: "#fff", textAlign: "center" }}>
                {formatNumber(videoDetails?.disLikes, "")}
              </Typography>
            </div>


            {/* Comment Button */}
            <div>
              <IconButton
                onClick={() => setIsOpen(!isOpen)}
                sx={{ color: "#fff" }}>
                <CommentIcon />
              </IconButton>
            </div>

            {/* Share Button */}
            <div>
              <RWebShare
                data={{
                  text: videoDetails?.description,
                  url: `${window.location.origin}/shorts/share/${videoDetails?.videoID}`,
                  title: videoDetails?.title,
                }}
              >
                <IconButton
                  sx={{ color: "#fff" }}>
                  <Share />
                </IconButton>
              </RWebShare>
            </div>

            {/* More Options (Ellipsis) */}
            <div>
              <IconButton
                onClick={() => setIsOptionsOpen(true)} sx={{ color: "#fff" }}>
                <MoreVert />
              </IconButton>
            </div>
          </div>
          <div className="foot">
            <div className="user-info">
              <div
                onClick={handleChannelClick}
                sx={{ cursor: "pointer" }}
              >
                {channel?.profilePic ? (
                  <img src={channel?.profilePic} alt="" />
                ) : (
                  <Skeleton variant="circular" width={40} height={40} />
                )}
                {channel?.name ? (
                  <span>{channel?.name}</span>
                ) : (
                  <Skeleton width={100} height={20} />
                )}
              </div>
              {channel && (
                <button
                  onClick={isUserChannel(user?.channelList, channel?.channelID) ? null : handleSubscribeClick}
                  disabled={isUserChannel(user?.channelList, channel?.channelID)}
                  style={{
                    backgroundColor: isUserChannel(user?.channelList, channel?.channelID) ? "grey" : "#FF5722", // Orange for others, grey for own channel
                    cursor: isUserChannel(user?.channelList, channel?.channelID) ? "not-allowed" : "pointer",
                    color: "white",
                  }}
                >
                  {channel?.isSubscribed === true || channel?.isSubscribed === "SUBSCRIBED"
                    ? "Unsubscribe"
                    : "Subscribe"}
                </button>
              )}

            </div>
            <div className="title">
              {short?.title ? (
                short?.title
              ) : (
                <Skeleton width="60%" height={20} />
              )}
            </div>
          </div>


          <div className="progress-bar-container">
            <Slider
              value={(currentTime / duration) * 100 || 0}
              onChange={(_, value) => {
                if (videoRef.current) {
                  videoRef.current.currentTime = (value / 100) * duration;
                }
              }}
              sx={{
                position: "absolute",
                bottom: 0, // Stick to bottom
                left: 0, // Start from left
                width: "100%", // Full width
                color: "blue",
                height: 4,
                "& .MuiSlider-track": {
                  backgroundColor: "blue",
                },
                "& .MuiSlider-rail": {
                  backgroundColor: "white",
                  opacity: 1,
                },
                "& .MuiSlider-thumb": {
                  display: "none",
                },
              }}
            />
          </div>
          {isOpen && (
            <div ref={commentBoxRef}
              style={{
                position: "absolute",
                bottom: "0%",
                width: "100%",
                background: "white",
                borderRadius: "10px",
                padding: "10px",
                height: "50vh",
                overflowY: "auto",
                display: "flex",
                flexDirection: "column"
              }}
            >
              {/* Sticky Header */}
              <div style={{ position: "sticky", top: 0, background: "white", paddingBottom: "5px", zIndex: 10 }}>
                <Typography variant="h6" style={{ fontSize: "18px" }}>Comments</Typography>
              </div>

              <div style={{ flex: 1, overflowY: "auto" }}>
                {replyView ? (
                  <>
                    {/* Original Comment with Close Icon */}
                    <Stack direction="row" spacing={2} alignItems="center" paddingBottom={1} borderBottom="1px solid #ddd">
                      <Avatar src={replyView.profilePic} alt={replyView.userName} />
                      <Stack>
                        <Typography variant="subtitle2" style={{ fontSize: "14px" }}>{replyView.userName}</Typography>
                        <Typography variant="body2" style={{ fontSize: "12px", color: "#555" }}>{replyView.message}</Typography>
                      </Stack>
                      <IconButton onClick={() => setReplyView(null)}>
                        <Close />
                      </IconButton>
                    </Stack>
                    {replies.length === 0 ? (
                      <Typography variant="body2" marginTop={1} style={{ fontSize: "12px" }}>
                        No replies yet!
                      </Typography>
                    ) : (
                      replies.map((reply) => (
                        <Stack key={reply.commentID} direction="row" spacing={2} alignItems="center" marginBottom={1} marginLeft={1}>
                          <Avatar src={reply.profilePic} alt={reply.userName} /> {/* Profile Picture */}
                          <Stack>
                            <Stack direction="row" spacing={1} alignItems="center">
                              <Typography variant="subtitle2" style={{ fontSize: "12px" }}>{reply.userName}</Typography>
                              <Typography variant="caption" color="textSecondary" style={{ fontSize: "10px" }}>
                                &nbsp;{`• ${moment(
                                  `${reply?.creationTime?.split("T")?.join(" ")} UTC`
                                ).fromNow()}`}
                              </Typography>
                            </Stack>
                            <Typography variant="body2" style={{ fontSize: "12px", color: "#555" }}>
                              {reply.message}
                            </Typography>
                            <Typography
                              variant="caption"
                              color="primary"
                              style={{ cursor: "pointer", fontSize: "12px" }}
                              onClick={() => { setReplyView(reply); fetchReplies(reply.commentID); }}
                            >
                              {reply.repiesCount} replies
                            </Typography>
                          </Stack>
                        </Stack>
                      ))
                    )}

                  </>
                ) : (
                  <>
                    {/* Main Comments List */}
                    {comments.length === 0 ? (
                      <Typography variant="body2" style={{ fontSize: "12px" }}>Be the first to comment!</Typography>
                    ) : (
                      comments.map((cmt) => (
                        <Stack key={cmt.commentID} direction="column" spacing={1} marginBottom={1}>
                          <Stack direction="row" spacing={2} alignItems="center">
                            <Avatar src={cmt.profilePic} alt={cmt.userName} />
                            <Stack>
                              <Stack direction="row" spacing={1} alignItems="center">
                                <Typography variant="subtitle2" style={{ fontSize: "14px" }}>{cmt.userName}</Typography>
                                <Typography variant="caption" color="textSecondary" style={{ fontSize: "10px" }}>
                                  &nbsp;{`• ${moment(
                                    `${cmt?.creationTime?.split("T")?.join(" ")} UTC`
                                  ).fromNow()}`}
                                </Typography>
                              </Stack>
                              <Typography variant="body2" style={{ fontSize: "12px", color: "#555" }}>{cmt.message}</Typography>
                              <Typography
                                variant="caption"
                                color="primary"
                                style={{ cursor: "pointer", fontSize: "12px" }}
                                onClick={() => {
                                  setReplyView(cmt);
                                  fetchReplies(cmt.commentID); // ✅ Fetch Replies when clicking
                                }}
                              >
                                {cmt?.repiesCount} replies
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      ))
                    )}
                  </>
                )}
              </div>

              {/* Sticky Input Box */}
              <div style={{ position: "sticky", bottom: 0, background: "white", paddingTop: "5px", zIndex: 10 }}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  {token ? (
                    <>
                      <TextField
                        inputRef={inputRef}
                        value={commentInput}
                        onChange={(e) => setCommentInput(e.target.value)}
                        placeholder={replyView ? `Reply to ${replyView.userName}` : "Add a comment"}
                        fullWidth
                        size="small"
                        style={{ fontSize: "12px" }}
                      />
                      <IconButton onClick={handleComment} color="primary">
                        <Send />
                      </IconButton>
                    </>
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      Please{" "}
                      <span
                        onClick={() => verifyAuth()}
                        style={{ cursor: "pointer", color: colors.blueDark }}
                      >
                        Login
                      </span>{" "}
                      to add a comment
                    </Typography>
                  )}
                </Stack>
              </div>
            </div>
          )}

          {/* More Options Popup */}
          {isOptionsOpen && (
            <div ref={optionsRef}
              style={{
                position: "absolute",
                bottom: "0%",
                width: "100%",
                background: "white",
                borderRadius: "10px",
                padding: "10px",
                maxHeight: "50vh",
                overflowY: "auto",
                display: "flex",
                flexDirection: "column",
                boxShadow: "0px -2px 10px rgba(0,0,0,0.1)"
              }}>

              {/* Header with Close Icon */}
              <Stack direction="row" justifyContent="space-between" alignItems="center" paddingBottom={1} borderBottom="1px solid #ddd">
                <Typography variant="h6" style={{ fontSize: "16px" }}>Options</Typography>
                <IconButton onClick={() => setIsOptionsOpen(false)}>
                  <Close />
                </IconButton>
              </Stack>

              {/* Option Items */}
              <Stack spacing={2} marginTop={2}>
                <Stack direction="row" alignItems="center" spacing={2} onClick={() => setIsSpeedMenuOpen(true)} style={{ cursor: "pointer" }}>
                  <Speed sx={{ color: "#555" }} />
                  <Typography variant="body1" style={{ fontSize: "14px" }}>Playback Speed</Typography>
                </Stack>

                <Stack direction="row" alignItems="center" spacing={2} onClick={() => { setIsOptionsOpen(false); setIsQualityMenuOpen(true); }} style={{ cursor: "pointer" }}>
                  <Settings sx={{ color: "#555" }} />
                  <Typography variant="body1" style={{ fontSize: "14px" }}>Quality</Typography>
                </Stack>

                <Stack direction="row" alignItems="center" spacing={2} onClick={() => setIsDescriptionMenuOpen(true)} style={{ cursor: "pointer" }}>
                  <Info sx={{ color: "#555" }} />
                  <Typography variant="body1" style={{ fontSize: "14px" }}>Description</Typography>
                </Stack>

                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  sx={{
                    cursor: isUserChannel(user?.channelList, channel?.channelID) ? 'not-allowed' : 'pointer',
                    color: isUserChannel(user?.channelList, channel?.channelID) ? 'grey' : reported ? 'blue' : 'default',
                  }}
                  onClick={isUserChannel(user?.channelList, channel?.channelID) ? null : handleSpamClick}
                >
                  <Report sx={{ color: isUserChannel(user?.channelList, channel?.channelID) ? 'grey' : reported ? 'blue' : 'default', }} />
                  <Typography variant="body1" style={{ fontSize: "14px" }}>Spam</Typography>
                </Stack>
              </Stack>
            </div>
          )}

          {/* Playback Speed Popup */}
          {isSpeedMenuOpen && (
            <div ref={speedRef}
              style={{
                position: "absolute",
                bottom: "0%",
                width: "100%",
                background: "white",
                borderRadius: "10px",
                padding: "10px",
                maxHeight: "50vh",
                overflowY: "auto",
                display: "flex",
                flexDirection: "column",
                boxShadow: "0px -2px 10px rgba(0,0,0,0.1)"
              }}>

              {/* Header with Back & Close Icon */}
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                paddingBottom={1}
                borderBottom="1px solid #ddd"
                sx={{
                  position: "sticky",
                  top: "-10px",
                  background: "white",
                  zIndex: 10
                }}
              >
                <Typography variant="h6" style={{ fontSize: "16px" }}>Playback Speed</Typography>
                <IconButton onClick={() => setIsSpeedMenuOpen(false)}>
                  <Close />
                </IconButton>
              </Stack>


              {/* Speed Options */}
              <Stack spacing={2} marginTop={2}>
                {speedOptions.map((speed) => (
                  <Stack
                    key={speed}
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => handleSpeedChange(speed)}
                  >
                    {selectedSpeed === speed && <Check sx={{ color: "black" }} />} {/* Show Check icon for selected */}
                    <Typography variant="body1" style={{ fontSize: "14px", fontWeight: selectedSpeed === speed ? "bold" : "normal" }}>
                      {speed}x {speed === 1 ? "(normal)" : ""}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </div>
          )}

          {/* Quality Menu */}
          {isQualityMenuOpen && (
            <div ref={qualityMenuRef}
              style={{
                position: "absolute",
                bottom: "0%",
                width: "100%",
                background: "white",
                borderRadius: "10px",
                padding: "10px",
                maxHeight: "50vh",
                overflowY: "auto",
                display: "flex",
                flexDirection: "column"
              }}
            >
              {/* Sticky Header */}
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                paddingBottom={1}
                borderBottom="1px solid #ddd"
                sx={{
                  position: "sticky",
                  top: 0,
                  background: "white",
                  zIndex: 10
                }}
              >
                <Typography variant="h6" style={{ fontSize: "16px" }}>Quality</Typography>
                <IconButton onClick={() => setIsQualityMenuOpen(false)}>
                  <Close />
                </IconButton>
              </Stack>

              {/* Quality Options */}
              {availableQualities.map((quality) => (
                <Stack
                  key={quality}
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  padding="10px"
                  onClick={() => handleQualityChange(quality)}
                  sx={{
                    cursor: "pointer",
                    "&:hover": { backgroundColor: "#f5f5f5" }
                  }}
                >
                  {/* Check Icon for Selected Quality */}
                  {selectedQuality === quality && <Check sx={{ color: "black" }} />}

                  {/* Quality Text */}
                  <Typography
                    variant="body1"
                    style={{ fontSize: "14px", fontWeight: selectedQuality === quality ? "bold" : "normal" }}
                  >
                    {quality}
                  </Typography>
                </Stack>
              ))}
            </div>
          )}

          {/* Description Menu */}
          {isDescriptionMenuOpen && (
            <div ref={descriptionMenuRef}
              style={{
                position: "absolute",
                bottom: "0%",
                width: "100%",
                background: "white",
                borderRadius: "10px",
                padding: "10px",
                height: "50vh",
                overflowY: "auto",
                display: "flex",
                flexDirection: "column"
              }}
            >
              {/* Sticky Header */}
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                paddingBottom={1}
                borderBottom="1px solid #ddd"
                sx={{
                  position: "sticky",
                  top: 0,
                  background: "white",
                  zIndex: 10
                }}
              >
                <Typography variant="h6" style={{ fontSize: "16px" }}>Description</Typography>
                <IconButton onClick={() => setIsDescriptionMenuOpen(false)}>
                  <Close />
                </IconButton>
              </Stack>

              {/* Description Content */}
              <Typography
                variant="body1"
                style={{ fontSize: "14px", padding: "10px", whiteSpace: "pre-wrap" }}
              >
                {videoDetails.description}
              </Typography>
            </div>
          )}


        </div>

      </div>
      <Dialog open={isSpamDialogOpen} onClose={() => setIsSpamDialogOpen(false)}>
        <DialogTitle>{reported ? "Remove Spam" : "Report Spam"}</DialogTitle>
        <DialogContent>
          {reported ? (
            <Typography>Do you want to remove the spam report?</Typography>
          ) : (
            <TextField
              label="Enter your reason for reporting"
              fullWidth
              multiline
              rows={4}
              value={spamMessage}
              onChange={(e) => setSpamMessage(e.target.value)}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsSpamDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={handleReport}
            color="primary"
            disabled={!reported && !spamMessage.trim()} // Disable if no message when reporting
          >
            {reported ? "Remove" : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

function isActiveVideo(videoRef) {
  if (!videoRef?.current) {
    return false; // Safeguard: Return false if the ref is not yet attached
  }
  const videoTop = videoRef.current.getBoundingClientRect().top;
  return videoTop > 0 && videoTop <= 150;
}

export default Short;
