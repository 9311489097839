import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Tooltip from '@mui/material/Tooltip';
import {
  CardMedia,
  Skeleton,
  Stack,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
} from "@mui/material";
import { isMobile } from "react-device-detect";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { PAGE_ROUTES } from "../../utils/constants";
import { VideoResponse } from "../../types/medial";
import VideoThumbnail from "../../assets/thumbnail.png";
import ShareIcon from "@mui/icons-material/Share";
import { RWebShare } from "react-web-share";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import {
  getVideoDetailsByID,
  reportVideo,
  deleteVideo,
  updateVideoDetail,
  getFeedbackStatus,
} from "../../services/APIServices/videos.api";
import { getChannelByID } from "../../services/APIServices/channel.api";
import { useAppSelector } from "../../store/hooks";
import { getUser } from "../../store/reducers/user.reducer";
import { isUserChannel } from "../../utils";
import { ChannelDetails } from "../../types/channel";
import { useAuth } from "../../context/AuthContext";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadVideo from "../UploadVideo/UploadVideo";
import { error } from "ajv/dist/vocabularies/applicator/dependencies";

interface VideoData extends VideoResponse {
  src?: string;
  title: string;
  channelName: string;
  creationTime: string;
  views: number;
}

interface MediaProps {
  small?: boolean;
  fullWidth?: boolean;
  isLoading?: boolean;
  data: VideoData[];
}

function truncateText(title: string, maxLength: number) {
  if (title) {
    if (title.length <= maxLength) {
      return title;
    }
    const words = title.split(" ");
    const truncatedWords = words.slice(0, maxLength);
    return truncatedWords.join(" ") + "...";
  }
  return "";
}

const getDefaultGrid = (max: number | undefined) =>
  max ? { xs: max } : { lg: 4, md: 4, sm: 4, xs: 6 };

export default function VideosCard(props: any) {
  const {
    small = false,
    fullWidth = false,
    isLoading = false,
    data = [],
    maxWidth,
    videoDetails,
    setVideoDetails
  } = props;
  const [videos, setVideos] = useState<VideoData[]>(data !== null ? data : []);
  const user = useAppSelector((state) => getUser(state));
  const [loading, setLoading] = useState(isLoading);
  const [gridProps, setGridProps] = useState<any>(getDefaultGrid(maxWidth));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuID, setMenuID] = useState<null | string>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); // State for delete confirmation dialog
  const [videoToDelete, setVideoToDelete] = useState<string>(""); // State to store the video ID to be deleted
  const [editVideoDetails, setEditVideoDetails] = useState<any>(null);
  const [editing, setEditing] = useState(false);
  const navigate = useNavigate();

  const [reported, setReported] = useState(false);
  const { verifyAuth, setOnLoginSuccess } = useAuth();
  const [isSpamDialogOpen, setIsSpamDialogOpen] = useState(false);
  const [spamMessage, setSpamMessage] = useState("");

  const width = "100%";
  const height = small ? 350 : 350;

  const fetchVideoDetails = (videoID: any) => {
    if (user?.userID) {
      try {
        getFeedbackStatus(user.userID, videoID).then(
          (feedbackStatus: any) => {
            setReported(feedbackStatus?.spam);
          }
        );
        } catch (error) {
        console.error("Error fetching video details or feedback status:", error);
      }
    }
  };

  const handleReport = (videoID: any) => {
    reportVideo(user?.userID as string, videoID as string, spamMessage).then(
      (res: any) => {
          fetchVideoDetails(videoID);
          setSpamMessage(""); // Clear the input
          setIsSpamDialogOpen(false); // Close the popup
      }
    );
  };
  const handleSpamClick = (videoID: any) => {
    fetchVideoDetails(videoID);
    if (verifyAuth()) {
      setIsSpamDialogOpen(true);
    } else {
      setOnLoginSuccess(() => setIsSpamDialogOpen(true));
    }
  };

  const handleEdit = (videoDetails: any) => {
    console.log("Edit video");
    setEditVideoDetails(videoDetails);
    setEditing(true);
    handleMenuClose();
    // updateVideoDetail(videoDetails).then((res: any) => {
    //   if (res.success) {
    //     console.log("Video details updated successfully:", res.message);
    //   } else {
    //     console.log("Failed to update video details:", res.message);
    //   }
    // });
  };

  const handleDelete = (videoId: string) => {
    // Open delete confirmation dialog
    setVideoToDelete(videoId);
    setDeleteDialogOpen(true);
  };

  const confirmDelete = () => {
    deleteVideo(videoToDelete).then((res: any) => {
      console.log(" deleteVideo : ", res);
        setVideos(videos.filter((video) => video.videoID !== videoToDelete));
        navigate(0);
    }, (error: any) => {
      console.log("Failed to delete video:", error);
    } );
    // Close delete confirmation dialog
    setDeleteDialogOpen(false);
  };

  useEffect(() => {
    if (data?.length) setVideos(data);
  }, [data]);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (!isMobile && fullWidth)
      setGridProps({
        lg: 8,
        md: 8,
        sm: 8,
      });
    else if (isMobile)
      setGridProps({
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12,
      });
    else if (small && !maxWidth)
      setGridProps({
        xl: 3,
        lg: 4,
        md: 4,
        sm: 4,
        xs: 4,
      });
    else setGridProps(getDefaultGrid(maxWidth));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, fullWidth, small, maxWidth]);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, id: string) => {
    setAnchorEl(event.currentTarget);
    setMenuID(id);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const formatNumber = (value: number | undefined, suffix: string): string => {
    if (value === undefined) return `0 ${suffix}`; 
    if (value >= 1e9) return `${(value / 1e9).toFixed(1)}B ${suffix}`;
    if (value >= 1e6) return `${(value / 1e6).toFixed(1)}M ${suffix}`;
    if (value >= 1e3) return `${(value / 1e3).toFixed(1)}K ${suffix}`;
    return `${value} ${suffix}`;
  };

  return (
    <React.Fragment>
      <Box
        sx={{ overflow: "hidden" }}
        alignItems="center"
        justifyContent={"center"}
      >
        <Grid
          container
          alignItems="center"
          justifyContent={"left"}
          // sx={{ rowGap: "10px", columnGap: "10px" }}
        >
          {Array.from(
            videos && videos?.length ? videos : new Array(isLoading ? 6 : 0)
          )?.map((video, index) => (
            <Grid
              item
              {...gridProps}
              key={index}
              sx={{
                padding: 1,
              }}
            >
              <Card
                key={index}
                sx={{
                  width: isMobile || fullWidth ? "100%" : width,
                  height: fullWidth ? "auto" : height,
                  position: "relative",
                  display: "flex",
                  flexDirection: fullWidth ? "row" : "column",
                  justifyContent: fullWidth ? "initial" : "space-between",
                  overflow: "hidden",
                  cursor: "pointer",
                }}
              >
                <>
                  {!loading ? (
                    <CardMedia
                      component="img"
                      sx={{
                        width: fullWidth ? 250 : "100%",
                        aspectRatio: "16/9",
                        objectFit: "cover",
                        backgroundColor: "#D0D4CA",
                      }}
                      alt={video?.title}
                      image={video?.thumbnailPic ?? VideoThumbnail}
                      onClick={() =>
                        navigate(`${PAGE_ROUTES.PLAYER_PAGE}/${video?.videoID}`)
                      }
                    />
                  ) : (
                    <Skeleton
                      variant="rectangular"
                      width={fullWidth ? 200 : "100%"}
                      height={fullWidth ? 152 : "50%"}
                    />
                  )}
                  <Box
                    sx={{
                      p: fullWidth ? 1 : 2,
                      marginLeft: fullWidth ? 3 : "auto",
                      width: "100%",
                    }}
                  >
                    {!loading ? (
                      <Stack direction={"column"}>
                        <Typography
                          gutterBottom
                          variant="h6"
                          lineHeight={fullWidth ? 2 : "auto"}
                          onClick={() =>
                            navigate(
                              `${PAGE_ROUTES.PLAYER_PAGE}/${video?.videoID}`
                            )
                          }
                          noWrap
                        >
                          {videos?.[index]?.title}
                        </Typography>
                        <Typography
                          display="block"
                          variant="caption"
                          color="text.secondary"
                          onClick={() =>
                            navigate(
                              `${PAGE_ROUTES.CHANNEL_PAGE}/profile/${video?.channelID}`
                            )
                          }
                        >
                          {videos?.[index]?.channelName}
                        </Typography>
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          width={"100%"}
                        >
                          {formatNumber(video?.views, "views")}
                        </Typography>
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          {videos?.[index]?.creationTime ? (
                            <Typography
                              variant="caption"
                              color="text.secondary"
                            >
                              {`Published • ${moment(
                                `${videos?.[index]?.creationTime
                                  ?.split("T")
                                  ?.join(" ")} UTC`
                              ).fromNow()}`}
                            </Typography>
                          ) : null}
                          <Tooltip title="More Options" arrow>
                          <IconButton
                            aria-label="more"
                            aria-controls={`video-menu-${index}`}
                            aria-haspopup="true"
                            onClick={(e) =>
                              handleMenuOpen(e, `video-menu-${index}`)
                            }
                          >
                            <MoreVertIcon />
                          </IconButton>
                          </Tooltip>
                        </Stack>
                      </Stack>
                    ) : (
                      <>
                        <Skeleton />
                        <Skeleton width="60%" />
                      </>
                    )}
                  </Box>
                </>
              </Card>
              <Menu
                id={`video-menu-${index}`}
                anchorEl={anchorEl}
                open={Boolean(anchorEl) && menuID === `video-menu-${index}`}
                onClose={handleMenuClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                PaperProps={{
                  style: {
                    width: "250px",
                  },
                }}
              >
                <RWebShare
                  data={{
                    text: video?.videoID,
                    url: `${window.location.origin}/watch/${video?.videoID}`,
                    title: video?.title,
                  }}
                >
                  <MenuItem>
                    <ListItemIcon>
                      <ShareIcon fontSize="small" />
                    </ListItemIcon>
                    Share
                  </MenuItem>
                </RWebShare>

                {/* Condition to check if user uploaded the video */}
                {isUserChannel(user?.channelList, video?.channelID) ? (
                  <>
                    <MenuItem onClick={() => handleEdit(video)}>
                      <ListItemIcon>
                        <EditIcon fontSize="small" />
                      </ListItemIcon>
                      Edit
                    </MenuItem>
                    <MenuItem onClick={() => handleDelete(video.videoID)}>
                      <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                      </ListItemIcon>
                      Delete
                    </MenuItem>
                  </>
                ) : (
                  <>
                  <Divider />
                <MenuItem onClick={() => handleSpamClick(video.videoID)}>
                  <ListItemIcon>
                    <ReportGmailerrorredIcon fontSize="small" />
                  </ListItemIcon>
                  Spam
                </MenuItem>
                  </>
                )}

                

                <Dialog open={isSpamDialogOpen} onClose={() => setIsSpamDialogOpen(false)}>
  <DialogTitle>{reported ? "Remove Spam" : "Report Spam"}</DialogTitle>
  <DialogContent>
    {reported ? (
      <Typography>Do you want to remove the spam report?</Typography>
    ) : (
      <TextField
        label="Enter your reason for reporting"
        fullWidth
        multiline
        rows={4}
        value={spamMessage}
        onChange={(e) => setSpamMessage(e.target.value)}
      />
    )}
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setIsSpamDialogOpen(false)}>Cancel</Button>
    <Button
      onClick={() => handleReport(video.videoID)}
      color="primary"
      disabled={!reported && !spamMessage.trim()} // Disable if no message when reporting
    >
      {reported ? "Remove" : "Submit"}
    </Button>
  </DialogActions>
</Dialog>
              </Menu>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this video?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="primary">
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
      {editing ? (
        <UploadVideo
          defaultOpen
          onModalClose={() => setEditing(false)}
          width={"80vw"}
          isEditing
          details={editVideoDetails}
          onEditSuccess={() => setEditing(false)}
        />
      ) : null}
    </React.Fragment>
  );
}
