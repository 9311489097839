import React, { useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Backdrop, Container, Snackbar, SnackbarContent, createTheme, useMediaQuery } from "@mui/material";
import { colors } from "../../theme/index.js";
import PrimarySearchAppBar from "../AppBar/AppBar";
import "./App.css";
import Channel from "../../pages/Channel/Channel";
import { ROUTES_PATH } from "../../utils/constants";
import CreateChannel from "../../pages/Channel/CreateChannel";
import Home from "../../pages/Home";
import { getUserDetails } from "../../services/APIServices/user.api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getUser, setUser } from "../../store/reducers/user.reducer";
import { BottomNav } from "../Drawer/Drawer";
import { isMobile } from "react-device-detect";
import SearchPage from "../../pages/Search/Search";
import ChannelProfile from "../../pages/Channel/ChannelProfile";
import { WatchPage } from "../../pages/WatchPage";
import UploadVideo from "../UploadVideo/UploadVideo";
import { grey } from "@mui/material/colors";
import Login from "../../pages/Login";
import UserSignup from "../../pages/UserSignup/UserSignup";
import UserLogin from "../../pages/UserLogin/UserLogin";
import ForgotPassword from "../../pages/ForgotPassword/ForgotPassword";
import ResetPassword from "../../pages/ResetPassword/ResetPassword";
import VerifyUser from "../../pages/VerifyUser/VerifyUser";
import { AuthProvider } from "../../context/AuthContext";
import Profile from "../../pages/Profile/Profile";
import { getMessage, setMessage } from "../../store/reducers/message.reducer";
import CircularProgressWithLabel from "../Progress/CircularProgressWithLabel";
import { getLoading, setLoading } from "../../store/reducers/loading.reducer";
import Shorts from "../../pages/Shorts/Shorts.tsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ShortContainer from "../../pages/Shorts/ShortContainer.jsx";

export const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

function App() {
    let dispatch = useAppDispatch();
    const user = useAppSelector((state) => getUser(state));
    const message = useAppSelector((state) => getMessage(state));
    const { isLoading, progress } = useAppSelector((state) => getLoading(state));
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [mode, setMode] = React.useState(prefersDarkMode ? 'dark' : 'light');

    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
            },
        }), [],
    );

    const theme = React.useMemo(
        () =>
        createTheme({
            palette: {
                mode,
                ...(mode === 'light' ? {
                    // palette values for light mode
                    primary: { main: colors.blueDark },
                    text: {
                        primary: grey[900],
                        secondary: grey[800],
                    },
                    secondary: {
                        main: colors.orangeDark,
                    },
                } : {
                    // palette values for dark mode
                    primary: { main: colors.blueDark },
                    secondary: {
                        main: colors.orangeDark,
                    },
                    text: {
                        primary: '#fff',
                        secondary: grey[500],
                    },
                }),
            },
        }), [mode],
    );

    useEffect(() => {
        // getUserDetails(user?.userID).then(res => { if (res?.userID) dispatch(setUser(res)) });
    }, [])

    return ( <
            div className = "App" >
            <
            ColorModeContext.Provider value = { colorMode } >
            <
            ThemeProvider theme = { theme } > {
                isLoading ?
                <
                Backdrop
                sx = {
                    { color: '#fff', zIndex: 10000 }
                }
                open = { isLoading } >
                <
                CircularProgressWithLabel value = { progress }
                /> < /
                Backdrop > : null
            } <
            Container maxWidth = { "xl" }
            sx = {
                { paddingTop: 10, paddingLeft: 0, paddingRight: 0 }
            } >
            <
            ToastContainer / >
            <
            BrowserRouter >
            <
            AuthProvider >
            <
            PrimarySearchAppBar / >
            <
            Container maxWidth = { isMobile ? 'xl' : 'lg' }
            sx = {
                { paddingBottom: 8, paddingLeft: isMobile ? 0 : 'initial', paddingRight: isMobile ? 0 : 'initial' }
            } >
            <
            Routes >
            <
            Route path = "/"
            element = { < Home / > }
            /> <
            Route path = { ROUTES_PATH.CHANNEL_LANDING }
            element = { < Channel / > }
            /> <
            Route path = { ROUTES_PATH.CHANNEL_PROFILE } >
            <
            Route path = ":channelID"
            element = { < ChannelProfile / > }
            /> < /
            Route > <
            Route path = { '/video/upload' }
            element = { < UploadVideo / > }
            /> <
            Route path = { ROUTES_PATH.CREATE_CHANNEL }
            element = { < CreateChannel / > }
            /> <
            Route path = { ROUTES_PATH.SEARCH }
            element = { < SearchPage / > }
            /> <
            Route path = { ROUTES_PATH.PLAYER }
            element = { < WatchPage / > }
            /> <
            Route path = { ROUTES_PATH.LOGIN }
            element = { < UserLogin redirectRequired = { true }
                />} / >
                <
                Route
                path = { '/usersignup' }
                element = { < UserSignup / > }
                /> <
                Route
                path = { '/forgotpassword' }
                element = { < ForgotPassword redirectRequired = { true }
                    />} / >
                    <
                    Route
                    path = { '/resetpassword' }
                    element = { < ResetPassword redirectRequired = { true }
                        />} / >
                        <
                        Route
                        path = { '/verifyuser' }
                        element = { < VerifyUser / > }
                        /> <
                        Route
                        path = { '/profile' }
                        element = { < Profile / > }
                        />  <
                        Route path = { '/shorts' }
                        element = { < ShortContainer / > }
                        />  <
                        Route path = { '/shorts/channel/:channelID/:videoID' }
                        element = { < ShortContainer / > }
                        />  <
                        Route path = { '/shorts/share/:videoID' }
                        element = { < ShortContainer / > }
                        /> <
                        Route path = { '/shorts/:videoID' }
                        element = { < ShortContainer / > }
                        /> <
                        /
                        Routes > < /
                        Container > { isMobile ? < BottomNav / > : null } <
                        /AuthProvider> < /
                        BrowserRouter > {
                            message ? < Snackbar
                            autoHideDuration = { 6000 }
                            anchorOrigin = {
                                { vertical: 'top', horizontal: 'center' }
                            }
                            open = { true }
                            onClose = {
                                () => dispatch(setMessage(null))
                            } >
                            <
                            SnackbarContent message = { message }
                            /> < /
                            Snackbar > : null
                        } <
                        /Container> < /
                        ThemeProvider > <
                        /ColorModeContext.Provider> < /
                        div >
                    );
                }

                export default App;