import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Stack,
  Typography,
  Grid,
  Avatar,
  Chip,
  Card,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Skeleton,
  Select,
  MenuItem,
  SpeedDialIcon,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { Check, Forward10, Hd, Pause, PlayArrow, Replay5, Report, Shortcut, Speed, ThumbDown, ThumbUp } from "@mui/icons-material";
import { RWebShare } from "react-web-share";
import { isMobile } from "react-device-detect";
import axios from "axios";

import "../Player/VideoPlayer.css";
import ReactPlayer from "react-player";
import {
  dislikeVideo,
  getFeedbackStatus,
  getVideoDetailsByID,
  likeVideo,
  reportVideo,
} from "../../services/APIServices/videos.api";
import { getChannelByID } from "../../services/APIServices/channel.api";
import { inscribeChannel } from "../../services/APIServices/user.api";
import { useAppSelector } from "../../store/hooks";
import { getUser } from "../../store/reducers/user.reducer";
import { PAGE_ROUTES } from "../../utils/constants";
import { isUserChannel } from "../../utils";
import { VideoResponse } from "../../types/medial";
import { ChannelDetails } from "../../types/channel";
import { useAuth } from "../../context/AuthContext";
import { Slider, IconButton, Box, Menu } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const baseURL = process.env.REACT_APP_SERVER_URL;

function VideoPlayer(props: any) {
  const { videoDetails, setVideoDetails, channel, setChannel } = props;
  let { videoID } = useParams();

  const [loading, setLoading] = useState(false);
  const user = useAppSelector((state) => getUser(state));
  const navigate = useNavigate();
  const player = useRef<any>();
  // const [quality, setQuality] = useState("360p");
  const [appBarHeight, setAppBarHeight] = useState<any>(10);
  const [playerHeight, setPlayerHeight] = useState<any>(720);
  const [playerWidth, setPlayerWidth] = useState<any>(1280);
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);
  const [reported, setReported] = useState(false);
  const { verifyAuth, setOnLoginSuccess } = useAuth();
  const [isSpamDialogOpen, setIsSpamDialogOpen] = useState(false);
  const [spamMessage, setSpamMessage] = useState("");
  const [showQualityOptions, setShowQualityOptions] = useState(false);
  const [showSpeedOptions, setShowSpeedOptions] = useState(false);
  const [selectedQuality, setSelectedQuality] = useState("Medium");
  const [selectedSpeed, setSelectedSpeed] = useState(1);
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => setExpanded(!expanded);

  useEffect(() => {
    fetchVideoDetails();
  }, [videoID]);

  const fetchVideoDetails = async () => {
    if (user?.userID && videoID) {
      try {
        const videoDetails = await getVideoDetailsByID(videoID);
        setVideoDetails(videoDetails);

        getFeedbackStatus(user.userID, videoID).then(
          (feedbackStatus: any) => {
            setLiked(feedbackStatus.like || false);
            setDisliked(feedbackStatus.dislike || false);
            setReported(feedbackStatus.spam || false);
          }
        );
      } catch (error) {
        console.error("Error fetching video details or feedback status:", error);
      }
    }
  };


  useEffect(() => {
    setAppBarHeight(document.getElementById("App-Bar")?.offsetTop);
  }, []);

  useEffect(() => {
    setLoading(true);
    if (videoDetails !== null && channel !== null) {
      setLoading(false);
    }
  }, [videoDetails]);

  const handleInscribe = () => {
    inscribeChannel(channel?.channelID as string, user?.userID).then(
      (res: any) => {
        let status = res?.message?.split(" ")?.[0];
        if (status && channel) {
          getChannelByID(videoDetails?.channelID as string, user?.userID).then(
            (res: any) => {
              setChannel(res);
              setLoading(false);
            }
          );
        }
      }
    );
  };

  const handleSubscribeClick = () => {
    if (verifyAuth()) {
      handleInscribe();
    } else {
      setOnLoginSuccess(handleInscribe);
    }
  };

  const handleDislike = () => {
    dislikeVideo(user?.userID as string, videoDetails?.videoID as string).then(
      (res: any) => {
        fetchVideoDetails();
        getVideoDetailsByID(videoDetails?.videoID as string).then(
          (res: any) => {
            setVideoDetails(res);
          }
        );
      }
    );
  };
  const handleDislikeClick = () => {
    if (verifyAuth()) {
      handleDislike();
    } else {
      setOnLoginSuccess(handleDislike);
    }
  };
  const handleReport = () => {
    reportVideo(user?.userID as string, videoDetails?.videoID as string, spamMessage).then(
      (res: any) => {
        fetchVideoDetails();
        getVideoDetailsByID(videoDetails?.videoID as string).then((res: any) => {
          setVideoDetails(res);
          setSpamMessage(""); // Clear the input
          setIsSpamDialogOpen(false); // Close the popup
        });
      }
    );
  };
  const handleSpamClick = () => {
    if (verifyAuth()) {
      setIsSpamDialogOpen(true);
    } else {
      setOnLoginSuccess(() => setIsSpamDialogOpen(true));
    }
  };

  const handleLike = () => {
    likeVideo(user?.userID as string, videoDetails?.videoID as string).then(
      (res: any) => {
        fetchVideoDetails();
        getVideoDetailsByID(videoDetails?.videoID as string).then(
          (res: any) => {
            setVideoDetails(res);
          }
        );
      }
    );
  };
  const handleLikeClick = () => {
    if (verifyAuth()) {
      handleLike();
    } else {
      setOnLoginSuccess(handleLike);
    }
  };
  const handlePlayerReady = () => {
    setPlayerHeight(document.getElementById("player-wrapper")?.offsetHeight);
    setPlayerWidth(document.getElementById("player-wrapper")?.offsetWidth);
    setLoading(false);
    setPlaying(true);
    setShowQualityOptions(false);
    setShowSpeedOptions(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  // const handleQualityChange = (event: any) => {
  //   const newQuality = event.target.value;
  //   setQuality(newQuality);
  //   player.current.seekTo(0); // Reset video to beginning when quality changes
  // };

  const handleChannelClick = () => {
    if (channel?.channelID) {
      navigate(`${PAGE_ROUTES.CHANNEL_PAGE}/profile/${channel?.channelID}`);
    }
  };

  const formatNumber = (value: number | undefined, suffix: string): string => {
    if (value === undefined) return `0 ${suffix}`;
    if (value >= 1e9) return `${(value / 1e9).toFixed(1)}B ${suffix}`;
    if (value >= 1e6) return `${(value / 1e6).toFixed(1)}M ${suffix}`;
    if (value >= 1e3) return `${(value / 1e3).toFixed(1)}K ${suffix}`;
    return `${value} ${suffix}`;
  };

  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [fullscreen, setFullscreen] = useState(false);
  const [selectOption, setSelectOption] = useState<String | null>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [subAnchorEl, setSubAnchorEl] = useState<HTMLElement | null>(null);
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const [isBuffering, setIsBuffering] = useState(false);

  const [playbackRate, setPlaybackRate] = useState(1);
  const [quality, setQuality] = useState("medium");
  const [showControls, setShowControls] = useState(true);

  const optionsOpen = Boolean(anchorEl);
  const subOptionsOpen = Boolean(subAnchorEl);

  const togglePlayPause = () => setPlaying(!playing);
  const toggleMute = () => setMuted(!muted);

  const handleFullscreenToggle = () => {
    const videoContainer = document.getElementById("video-container");
    if (!videoContainer) return;

    if (!document.fullscreenElement) {
      videoContainer.requestFullscreen().then(() => {
        const screenOrientation = window.screen.orientation as any;
        if (screenOrientation.lock) {
          screenOrientation.lock("landscape").catch((err: any) => console.log("Orientation error:", err));
        }
      });
    } else {
      document.exitFullscreen();
      const screenOrientation = window.screen.orientation as any;
      if (screenOrientation.unlock) {
        screenOrientation.unlock();
      }
    }
  };

  const formatTime = (time: number) => {
    if (isNaN(time) || time < 0) {
      return "00:00";
    }

    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);

    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  };


  useEffect(() => {
    const interval = setInterval(() => {
      if (player.current) {
        const current = player.current.getCurrentTime();
        const total = player.current.getDuration();
        setCurrentTime(current);
        setDuration(total);
      }
    }, 500);
    return () => clearInterval(interval);
  }, []);

  const handleQualityChange = (q: string) => {
    setLoading(true);
    if (player.current) {
      const current = player.current.getCurrentTime();
      setCurrentTime(current);
      setSelectedQuality(quality);
      setQuality(q);

      setTimeout(() => {
        if (player.current) {
          player.current.seekTo(current);
          setLoading(false);
        }
      }, 10);
    }
    setShowQualityOptions(false);
  };

  const handleSpeedChange = (speed: any) => {
    setLoading(true);
    setSelectedSpeed(speed);
    setShowSpeedOptions(false);
    setPlaybackRate(speed);
    setLoading(false);
  };

  const seek = (seconds: number) => {
    if (player.current) {
      const newTime = player.current.getCurrentTime() + seconds;
      player.current.seekTo(newTime, "seconds");
    }
  };

  return (
    <>
      <div onContextMenu={(e) => e.preventDefault()}>
        <div
          id={"player-wrapper"}
          className={`player-wrapper ${isMobile ? "fixed-top" : ""}`}
          style={{
            top: (appBarHeight - 25) as any,
            backgroundColor: "black",
            zIndex: 999,
          }}
        >
          <Box
            id="video-container"
            position="relative"
            width="100%"
            height="100%"
            bgcolor="black"
            onMouseEnter={() => setShowControls(true)}
            onMouseLeave={() => setShowControls(false)}
          >
            {/* Blue Circle Loader */}
            {loading && (
              <Box
                position="absolute"
                top="50%"
                left="50%"
                sx={{ transform: "translate(-50%, -50%)" }}
              >
                <CircularProgress sx={{ color: "blue" }} />
              </Box>
            )}

            {/* Video Player */}
            <ReactPlayer
              ref={player}
              url={`${baseURL}video/getVideo?watch=${videoID}&quality=${quality}`}
              className={`react-player ${isMobile ? "absolute" : ""}`}
              onReady={() => handlePlayerReady()}
              width="100%"
              height="100%"
              playing={playing}
              muted={muted}
              controls={false}
              playbackRate={playbackRate}
              config={{ file: { attributes: { controlsList: "nodownload" } } }}
            />

            {/* Controls */}
            {showControls && (

              <><Box
                position="absolute"
                top="50%"
                left="50%"
                sx={{
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <IconButton onClick={() => seek(-5)} sx={{ color: "white", fontSize: 40 }}>
                  <Replay5 fontSize="large" />
                </IconButton>

                <IconButton onClick={togglePlayPause} sx={{ color: "white", fontSize: 50 }}>
                  {playing ? <Pause fontSize="large" /> : <PlayArrow fontSize="large" />}
                </IconButton>

                <IconButton onClick={() => seek(10)} sx={{ color: "white", fontSize: 40 }}>
                  <Forward10 fontSize="large" />
                </IconButton>
              </Box><Box
                position="absolute"
                bottom={0}
                left={0}
                width="100%"
                color="white"
                px={2}
                py={1}
              >
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    {/* Left Controls */}
                    <Box display="flex" alignItems="center" gap={2}>
                      <IconButton onClick={togglePlayPause} color="inherit">
                        {playing ? <PauseIcon /> : <PlayArrowIcon />}
                      </IconButton>
                      <Typography>
                        {formatTime(currentTime)} / {formatTime(duration)}
                      </Typography>
                    </Box>

                    {/* Right Controls */}
                    <Box display="flex" alignItems="center" gap={2}>
                      <Tooltip title={muted ? "Unmute" : "Mute"}>
                        <IconButton onClick={toggleMute} color="inherit">
                          {muted ? <VolumeOffIcon /> : <VolumeUpIcon />}
                        </IconButton>
                      </Tooltip>

                      {/* Speed Icon */}
                      <Tooltip title="Playback Speed">
                        <IconButton onClick={() => { setShowSpeedOptions(!showSpeedOptions); setShowQualityOptions(false); }} color="inherit">
                          <Speed />
                        </IconButton>
                      </Tooltip>
                      {showSpeedOptions && (
                        <Box className="popup" position="absolute" bottom={80} right={90} bgcolor="black" color="white" p={1} borderRadius={2} width={105} maxHeight={150} overflow="auto">
                          {[0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2].map((speed) => (
                            <Box key={speed} p={1} display="flex" justifyContent="space-between" alignItems="center" onClick={() => handleSpeedChange(speed)}>
                              {selectedSpeed === speed && <Check />}
                              {speed}x
                            </Box>
                          ))}
                        </Box>
                      )}

                      <Tooltip title="Video Quality">
                        <IconButton onClick={() => { setShowQualityOptions(!showQualityOptions); setShowSpeedOptions(false); }} color="inherit">
                          <Hd />
                        </IconButton>
                      </Tooltip>
                      {showQualityOptions && (
                        <Box className="popup" position="absolute" bottom={80} right={50} bgcolor="black" color="white" p={1} borderRadius={2}>
                          {["Low", "Medium", "High"].map((quality) => (
                            <Box key={quality} p={1} display="flex" justifyContent="space-between" alignItems="center" onClick={() => handleQualityChange(quality)}>
                              {selectedQuality === quality && <Check />}
                              {quality}
                            </Box>
                          ))}
                        </Box>
                      )}

                      <Tooltip title={fullscreen ? "Exit Fullscreen" : "Fullscreen"}>
                        <IconButton onClick={handleFullscreenToggle} color="inherit">
                          {fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                        </IconButton>
                      </Tooltip>
                      {/* Quality and Playback Speed Icons */}
                    </Box>
                  </Box>

                  <Slider
                    value={(currentTime / duration) * 100 || 0}
                    onChange={(_, value: any) => {
                      if (player.current) {
                        player.current.seekTo((value / 100) * duration);
                      }
                    }}
                    sx={{
                      color: "blue",
                      height: 4,
                      "& .MuiSlider-track": {
                        backgroundColor: "blue",
                      },
                      "& .MuiSlider-rail": {
                        backgroundColor: "white",
                        opacity: 1,
                      },
                      "& .MuiSlider-thumb": {
                        display: "none",
                      },
                    }} />
                </Box></>
            )}
          </Box>

        </div>
        {isMobile ? (
          <div
            className={"player-placeholder"}
            style={{
              paddingBottom: `${100 / (playerWidth / playerHeight)}%`,
            }}
          />
        ) : null}
        {!loading ? (
          <div>
            <Stack>
              <Typography gutterBottom variant="h6" sx={{ fontWeight: "bold" }}>
                {videoDetails?.title}
              </Typography>
              <Typography
                gutterBottom
                variant="caption"
                sx={{ color: "GrayText" }}
              >
                {formatNumber(videoDetails?.views, "views")}
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              sx={{ alignItems: "center", marginTop: 2 }}
            >
              <Grid container rowSpacing={2}>
                <Grid item xs={12} md={7}>
                  <Stack direction={"row"} sx={{ alignItems: "center" }}>
                    <Avatar
                      alt={""}
                      src={channel?.profilePic as string}
                      sx={{
                        width: 56,
                        height: 56,
                        marginRight: 2,
                        cursor: "pointer",
                      }}
                      onClick={handleChannelClick}
                    />
                    <Stack direction={"column"}>
                      <Typography
                        gutterBottom
                        variant="body1"
                        onClick={handleChannelClick}
                        sx={{ cursor: "pointer" }}
                      >
                        {channel?.name}
                      </Typography>
                      <Typography
                        gutterBottom
                        variant="caption"
                        sx={{ color: "GrayText" }}
                      >
                        {`${channel?.subscriberCount
                          ? channel?.subscriberCount
                          : 0
                          } subscribers`}
                      </Typography>
                    </Stack>
                    <Chip
                      size="medium"
                      color="secondary"
                      label={`${(typeof channel?.isSubscribed === "boolean" &&
                        typeof channel?.isSubscribed) ||
                        channel?.isSubscribed === "SUBSCRIBED"
                        ? "Unsubscribe"
                        : "Subscribe"
                        }`}
                      sx={{
                        cursor: "pointer",
                        marginInline: 2,
                        marginLeft: "auto",
                        right: 0,
                      }}
                      onClick={() => handleSubscribeClick()}
                      disabled={isUserChannel(
                        user?.channelList,
                        channel?.channelID
                      )}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={5} sx={{ alignItems: "center" }}>
                  <Stack
                    direction={"row"}
                    sx={{
                      height: "100%",
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "space-evenly",
                    }}
                    gap={"6px"}
                  >
                    <Chip
                      icon={<ThumbUp />}
                      label={formatNumber(videoDetails?.likes, "")}
                      sx={{
                        marginRight: 1,
                        backgroundColor: liked ? "#027fff" : "",
                        color: liked ? "white" : "",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleLikeClick();
                      }}
                      disabled={isUserChannel(
                        user?.channelList,
                        channel?.channelID
                      )}
                    // sx={{ marginInline: "1rem" }}
                    />
                    <Chip
                      icon={<ThumbDown />}
                      label={formatNumber(videoDetails?.disLikes, "")}
                      sx={{
                        marginRight: 1,
                        backgroundColor: disliked ? "#027fff" : "",
                        color: disliked ? "white" : "",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleDislikeClick();
                      }}
                      disabled={isUserChannel(
                        user?.channelList,
                        channel?.channelID
                      )}
                    // sx={{ marginInline: "1rem" }}
                    />
                    <Chip
                      icon={<Report />}
                      label={formatNumber(videoDetails?.spam || 0, "")}
                      onClick={() => {
                        handleSpamClick();
                      }}
                      disabled={isUserChannel(
                        user?.channelList,
                        channel?.channelID
                      )}
                      // sx={{ marginInline: "1rem" }}
                      sx={{
                        backgroundColor: (() => {
                          const spamCount = videoDetails?.spam || 0;
                          if (spamCount < 50) return "green";
                          if (spamCount >= 50 && spamCount < 100) return "yellow";
                          if (spamCount >= 100 && spamCount < 200) return "red";
                          return "darkred";
                        })(),
                        color: "white",
                        cursor: "pointer",
                      }}
                    />

                    <RWebShare
                      data={{
                        text: videoDetails?.description,
                        url: window.location.href,
                        title: videoDetails?.title,
                      }}
                    >
                      <Chip
                        icon={<Shortcut />}
                        label={"Share"}
                        onClick={() => { }}
                      // sx={{ marginInline: "1rem" }}
                      />
                    </RWebShare>
                  </Stack>
                </Grid>
                {/* <Card
                  sx={{
                    marginTop: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    justifyContent: "left",
                    textAlign: "left",
                    padding: 2,
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    {videoDetails?.description || "No description..."}
                  </Typography>
                </Card> */}

                <Card
                  sx={{
                    mt: 2,
                    p: 2,
                    width: "100%",
                    border: "1px solid #ddd",
                    backgroundColor: "#f9f9f9",
                    borderRadius: 2,
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      textAlign: "left",
                      display: "-webkit-box",
                      WebkitLineClamp: expanded ? "unset" : 3, // Show only 3 lines initially
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      whiteSpace: expanded ? "normal" : "nowrap",
                    }}
                  >
                    {videoDetails?.description?.trim() || "No description available."}
                  </Typography>

                  {videoDetails?.description && videoDetails?.description.length > 100 && ( // Only show button if description is long
                    <Button
                      size="small"
                      sx={{ mt: 1, textTransform: "none", color: "#1976d2", fontWeight: 500 }}
                      onClick={toggleExpand}
                    >
                      {expanded ? "Show Less" : "Show More"}
                    </Button>
                  )}
                </Card>
              </Grid>
            </Stack>
          </div>
        ) : (
          <>
            <Stack>
              <Skeleton
                variant="text"
                width={"100%"}
                sx={{ fontSize: "1.5rem" }}
              />
            </Stack>
            <Stack
              direction={"row"}
              sx={{ alignItems: "center", marginTop: 2 }}
            >
              <Grid container>
                <Grid item>
                  <Stack direction={"row"}>
                    <Skeleton
                      variant="circular"
                      width={56}
                      height={56}
                      sx={{ marginRight: 2 }}
                    />
                    <Stack direction={"column"}>
                      <Skeleton variant="text" width={150} />
                      <Skeleton variant="text" width={100} />
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </>
        )}

        <Dialog open={isSpamDialogOpen} onClose={() => setIsSpamDialogOpen(false)}>
          <DialogTitle>{reported ? "Remove Spam" : "Report Spam"}</DialogTitle>
          <DialogContent>
            {reported ? (
              <Typography>Do you want to remove the spam report?</Typography>
            ) : (
              <TextField
                label="Enter your reason for reporting"
                fullWidth
                multiline
                rows={4}
                value={spamMessage}
                onChange={(e) => setSpamMessage(e.target.value)}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsSpamDialogOpen(false)}>Cancel</Button>
            <Button
              onClick={handleReport}
              color="primary"
              disabled={!reported && !spamMessage.trim()} // Disable if no message when reporting
            >
              {reported ? "Remove" : "Submit"}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}

export default VideoPlayer;
